import _uniq from 'lodash/uniq';
import _groupBy from 'lodash/groupBy';
import { removeSpacesPhone } from '../../../../lib/Utils';
import $ from 'jquery';

LegalFilesDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$timeout',
  '$http',
  '$q',
  'LegalFile',
  '$window',
  '$document',
  '$routeParams',
  '$dialog',
  'serverAddress',
  'notifications',
  'dataLoader',
  'LegalfilesConstants',
  'DocumentDownloadFactory',
  'LegalFileFactory',
  'DialogFactory',
  'DialogFactoryV2',
  'AirlineOpposingLawyer',
  'DocumentChangeFactory',
  'sameFlightCSV',
  'DocxTemplates',
  'fileUploaderGenerator',
  'CostsProceduresService',
  'ProceduralPhaseService',
  'ProceduralLawyerService',
  'DocTools',
  'ValidationService'
];

export default function LegalFilesDetailCtrl(
  $scope,
  $controller,
  $timeout,
  $http,
  $q,
  LegalFile,
  $window,
  $document,
  $routeParams,
  $dialog,
  serverAddress,
  notifications,
  dataLoader,
  LegalfilesConstants,
  DocumentDownloadFactory,
  LegalFileFactory,
  DialogFactory,
  DialogFactoryV2,
  AirlineOpposingLawyer,
  DocumentChangeFactory,
  sameFlightCSV,
  DocxTemplates,
  fileUploaderGenerator,
  CostsProceduresService,
  ProceduralPhaseService,
  ProceduralLawyerService,
  DocTools,
  ValidationService
) {
  const vm = this;

  vm.fileId = $routeParams.id;

  const INITIAL_STATE = {
    legalfile: {
      documents: [],
      claims_same_legalfile: [],
      costs_procedures: [],
      made_quantity: 0,
      legal_costs_certain_amount: 0,
      legal_interests_amount: 0,
      legal_costs_amount: 0,
      judicial_tax_amount: 0
    },
    currentLegalFilePrefix: '',

    costsProcedureList: [],
    costsProceduresUpdatedList: new Map(),

    segment: {
      app_name: null
    },
    trialMunicipalities: [],
    airlines: [],
    statesWithEmail: [],

    has_preview_email: false,

    changeButtonDisabled: false,

    sameflight: true,
    costsDetails: [],
    costsDetailsPanel: [],
    askForProcurador: false,
    not_send_button_doc_types: [20, 155],

    displayStatusComment: false,
    stateChangeComment: '',
    displayLawyerChange: false,
    displayClientAttendance: false,

    generateLawsuitButton: true,
    displayChangeCourt: false,
    legal_costs_doc: false,

    sendingEmail: true,

    externalLawyerActive: true,
    lawyerActive: true,
    procuradorActive: true,

    docxtemplates: [],
    /* Constants */
    AMOUNTS_TYPES: LegalfilesConstants.AMOUNTS_TYPES,
    ordinary_procesal_phase_options: LegalfilesConstants.ordinary_procesal_phase_options,
    verbal_procesal_phase_options: LegalfilesConstants.verbal_procesal_phase_options,
    PROCURADOR_BILL_CONCEPT: LegalfilesConstants.PROCURADOR_BILL_CONCEPT,
    EXTERNAL_LAWYER_BILL_CONCEPT: LegalfilesConstants.EXTERNAL_LAWYER_BILL_CONCEPT,
    VIEW_TYPE: LegalfilesConstants.VIEW_TYPE,

    tabConfig: LegalfilesConstants.tabConfig,

    //Dictionaries
    procuradores: [],
    courts: [],
    courtlawyers: [],
    externalcourtlawyers: [],
    provinces: [],
    costStates: [],
    unpaidStates: [],

    claimStates: [],
    hasInactiveClient: false,
    viewCancelledClientPermission: true,

    // Validated document
    validateDetails: {},
    showPartialDetails: false,

    // viewer

    // initial data
    documentGroups: [
      {
        id: 6,
        key: 'validDocs',
        name: 'Documentos del expediente',
        objsKey: 'legalfilesProcessed',
        docTypesKey: null,
        actions: ['download']
      },
      {
        id: 2,
        key: 'invalidDocs',
        name: 'Sentencias al mismo vuelo',
        objsKey: 'sentencesProcessed',
        docTypesKey: null,
        actions: ['download']
      },
      {
        id: 100,
        key: 'unvalidatedDocs',
        name: 'Documentos del expediente sin validar',
        objsKey: 'unvalidatedDocuments',
        docTypesKey: null,
        actions: ['download']
      }
    ],

    // viewer data
    availableDocumentGroups: [], // documentGroups with available documents
    activeDocumentGroup: {}, // the active documentGroup
    activeGroupDocs: [], // the documents from the active document group
    activeDocument: null, // the document in the viewers

    // viewer methods
    initViewer,
    _resetViewer,
    setDocumentGroup,
    getDocumentGroup
  };

  const METHODS = {
    /* Functions */
    init,
    initDictionaries,
    update,
    updateFile: update,
    exportSameFlightCSV,
    changeClaimState,
    changeStatus,
    changeClaimLawyer,
    changeLawyer,

    sendDocument,
    generateAppendix,
    sendDocsToLawyer,
    sendDocsToLegalExpert,
    openDrive,
    generateLawsuit,
    hasLawsuit,
    changeDocumentType,
    downloadDocumentDirectly,
    inExtrajudAgreement,
    deleteDocument,
    addClaimToFile,
    removeClaim,
    sentenceClaim,
    updateLegalFileInfo,
    updateLegalFileInterests,
    calculateLegalFileInterests,
    uncertainAmount,
    changeCourt,
    calculateLegalFileCosts,
    updateAirlines,
    generateModelPDF,
    generateFromDocxTemplate,
    loadDocxTemplates,
    processTrialCity,
    checkButtonDisabled,
    sendSignaturitLawsuitToClient,
    isExternalLawyerActive,
    isLawyerActive,
    isProcuradorActive,
    downloadSplittedLawsuitAppendix,
    addAmountDetail,
    removeAmountDetail,
    addCostsDetailToFile,
    isLegalFile,
    changeCostsProcedureState,
    getCostsProcedures,
    getFileUploaderInstance,
    updateProcedureState,
    getProcedureComment,
    getProcedureCommentById,
    getCostsDocumentType,
    showCommentOnUpdateProcedureState,
    hideCommentOnUpdateProcedureState,
    setLawyers,
    getSegmentID,
    addCommentSucessCb,
    getDefaultsbyTrialCity,
    getPhoneNumbersForExtCourtLawyer,
    getPhoneNumbersForProcurador,
    getPhoneIsValid,
    validateDocument,
    confirmValidateDocument,
    toggleValidatedModal,
    displayProcuratorComment
  };

  function getSegmentID(prefix) {
    const segmentIDs = {
      AE: 1,
      BA: 2,
      LA: 3,
      TE: 4,
      SO: 5,
      AC: 6,
      TA: 7,
      NE: 8
    };
    return segmentIDs[prefix] || 0;
  }

  function getCostsDocumentType(id) {
    const docTypes = [
      { id: 23, name: 'Sentencia' },
      { id: 489, name: 'Sentencia Desfavorable' },
      { id: 490, name: 'Sentencia Favorable' },
      { id: 491, name: 'Sentencia Parcialmente Favorable' },
      { id: 478, name: 'Transferencia / emisión de mandamiento de pago' },
      { id: 26, name: 'Diligencia de ordenación' },
      { id: 155, name: 'Minuta' },
      { id: 208, name: 'Aprobación de costas' }
    ];
    return docTypes.find(it => it.id === id);
  }

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS);

  /* Controller startup */
  init();

  // EVENTS
  $scope.$on('document:rename', (e, docData) => {
    vm.busy = true;

    DocTools.renameDocument(docData, vm.entityData)
      .then(() => {
        vm.update(`Actualizado nombre de documento "${docData.newName}"`);
        $scope.$broadcast('document:renamed');
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
        vm.busy = false;
        return false;
      });
  });

  /* Methods */
  function init() {
    // prevent loading ...legalfile/ID%23HASH urls
    if(vm.fileId.indexOf && vm.fileId.indexOf('#') != -1) {
      vm.fixIdFromHash(vm.fileId);
      return;
    }

    // First legalfile detail, then dictionaries
    vm.update()
      .then(legalfile => {
        // Check segment associated
        /* Some dictionaries depends on segment associated to legalfile, so we have two choices:
          1. Take segment from route and mapping with harcoded local function
          2. Take segment from legalfile.prefix and mapping with harcoded local function
        */
        vm.currentSegmentAssociated = legalfile.prefix === 'IM' ? legalfile.real_prefix : vm.legalfile.prefix;
        const entitiesForLawyers = vm.setLawyers(vm.currentSegmentAssociated);

        vm.segment.app_name = entitiesForLawyers.segmentName;

        vm.loadDocxTemplates();

        vm.hasInactiveClient = legalfile.claims_same_legalfile.some(claim => claim.client__user__is_active == false);

        /* Dictionaries */
        const dictionaries = {
          claimStates: {
            entity: 'claimstates',
            data: { segments__id: vm.getSegmentID(vm.currentSegmentAssociated), costs: false }
          },
          unpaidStates: 'unpaidstates',
          costStates: {
            entity: 'costsclaimstates',
            data: { segments__id: vm.getSegmentID(vm.currentSegmentAssociated), costs: true }
          },
          courts: 'courts',
          courtlawyers: 'courtlawyers',
          documentTypes: {
            entity: 'documenttypes',
            data: { type: 'aircase' }
          },
          externalcourtlawyers: entitiesForLawyers.externalcourtlawyers,
          lawyers: entitiesForLawyers.lawyers,
          procuradores: 'procuradores',
          provinces: 'provinces',
          statesWithEmail: {
            entity: 'statesWithPreviewEmail',
            data: { django_class: 'AirlineComplaint' }
          },
          trialMunicipalities: 'trialMunicipalities',
          proceduralPhases: 'proceduralPhases',
          proceduralLawyers: 'proceduralLawyers'
        };

        // Caso especial para expedientes de AE
        if (vm.legalfile.real_prefix === 'AE') {
          vm.tabConfig.push({
            heading: 'Mismo vuelo',
            indexKey: 'mismo-vuelo',
            template: 'js/modules/segments/legalfiles/partials/detail/sameFlight.html'
          });
        }

        vm.setTabs();

        return initDictionaries(dictionaries);
      })
      .then(data => {
        // Parse municipalities
        vm.trialMunicipalities = data['trialMunicipalities']['objects'];
      })
      .catch(error => {
        if (error['data'] && error['data'] == 'No tienes permisos para ver expedientes de usuarios cancelados.'){
          notifications.addCurrentView('error', error['data']);
          vm.viewCancelledClientPermission = false;
          vm.hasInactiveClient = true;
        }
        console.error(error);
      });
  }

  function initDictionaries(dictionaries) {
    return vm.getDictionaries(dictionaries);
  }

  function setLawyers(legalfilePrefix) {
    let entities = {
      lawyers: '',
      externalcourtlawyers: '',
      segmentName: ''
    };
    switch (legalfilePrefix) {
      case 'AE':
        entities.lawyers = 'lawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_forairlines';
        entities.segmentName = 'complaints';
        break;
      case 'BA':
        entities.lawyers = 'banklawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_forbanking';
        entities.segmentName = 'banking';
        break;
      case 'AC':
        entities.lawyers = 'accidentlawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_foraccidents';
        entities.segmentName = 'accidents';
        break;
      case 'TA':
        entities.lawyers = 'taxeslawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_fortaxes';
        entities.segmentName = 'taxes';
        break;
      case 'LA':
        entities.lawyers = 'laborallawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_forlaboral';
        entities.segmentName = 'laboral';
        break;
      case 'NE':
        entities.lawyers = 'negligenceslawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_fornegligences';
        entities.segmentName = 'negligences';
        break;
      case 'TE':
        entities.lawyers = 'phonelawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_forphone';
        entities.segmentName = 'phone';
        break;
      case 'SO':
        entities.lawyers = 'phonelawyers';
        entities.externalcourtlawyers = 'externalcourtlawyers_forphone';
        entities.segmentName = 'segundaoportunidad';
        break;
      default:
        throw new Error('Invalid segment prefix');
    }
    return entities;
  }

  function update(msg) {
    let deferred = $q.defer();
    const promise = LegalFile.get({ id: vm.fileId }).$promise;
    promise
      .then(legalfile => {

        vm.claimLoadError = null;

        // TODO: Remove when back returns currencies in right form
        legalfile.claimed_total = Number(legalfile.claimed_total);
        legalfile.judicial_tax_amount = Number(legalfile.judicial_tax_amount);
        legalfile.legal_costs_amount = Number(legalfile.legal_costs_amount);
        legalfile.legal_costs_certain_amount = Number(legalfile.legal_costs_certain_amount);
        legalfile.legal_interests_amount = Number(legalfile.legal_interests_amount);
        legalfile.made_quantity = Number(legalfile.made_quantity);

        vm.currentSegmentAssociated = legalfile.prefix === 'IM' ? legalfile.real_prefix : legalfile.prefix;
        vm.setEntityData({ legalfile_id: legalfile.id, prefix: legalfile.prefix }, {});
        vm.assume_legal_costs =
        legalfile.claims_same_legalfile === null ? null : legalfile.claims_same_legalfile[0].assume_legal_costs;
        
        vm.legal_costs_doc = legalfile.documents.some(doc => doc.legal_costs_doc === true);
        
        // Reset updated changes on costs list
        vm.costsProceduresUpdatedList = new Map();

        getCostsProcedures(legalfile.id, list => {
          vm.costsProcedureList = list;
        });

        if (vm.costsDetails.length === 0) {
          vm.costsDetails.push({ concept: 'Minuta reclamador', amount: 0 });
        }

        // Load legalexperts if needed
        if (legalfile.prefix === 'TA') {
          dataLoader('legalexperts').$promise
            .then(response => {
              vm.legalexperts = response.objects;
            });
        }

        if (!legalfile.court) {
          vm.displayChangeCourt = true;
        }

        vm.displayClientAttendance = legalfile.claims_state === 170;

        vm.externalLawyerActive = vm.isExternalLawyerActive();
        vm.lawyerActive = vm.isLawyerActive();
        vm.procuradorActive = vm.isProcuradorActive();

        /* Set legalfile data */
        vm.legalfile = legalfile;
        // TODO: This code makes no sense, segment endpoint only returns app_name on body when app_name params is sent, and it's the same value!
        // Now load docxtemplates if needed
        if (!vm.segment.app_name) {
          vm.loadDocxTemplates();
        }

        // Airlines shit
        loadAirlinesued();
        if (legalfile.prefix === 'AE') {
          hasSameFlight();
          let airlineIds = extractLegalFileAirlineIds();
          AirlineOpposingLawyer.get({ airlineId: airlineIds }).$promise
            .then(response => {
              vm.airline_opposing_lawyer = response.objects;
            });
        }

        vm.setTabs();

        if (msg) notifications.addCurrentView('success', msg);
        deferred.resolve(legalfile);
      })
      .catch(error => {
        console.error(error);
        vm.claimLoadError = error;
        deferred.reject(error);
      })
      .finally(() => {
        vm.busy = false;
      });
    return deferred.promise;
  }

  //TODO: Grouping Procedurelist in back-end
  function getCostsProcedures(id, callback) {
    CostsProceduresService.get(id)
      .then(res => {
        let proceduresGroup = _groupBy(res.data, 'costs_procedure.procedure_id');
        if (callback) {
          $timeout(() => callback(proceduresGroup));
        }
      })
      .catch(err => console.error(err));
  }

  /* File uploader */

  const fileUploder = fileUploaderGenerator({
    vm,
    url: serverAddress.getDocumentUrl() + 'legalfile/upload/',
    fillItemData: cbBeforeDocumentUpload,
    checkUploadItem,
    sucessCallback: cbAfterDocumentUpload,
    errorCallback,
    testChangeDocumentType: handlerChangeDocumentType
  });

  function getFileUploaderInstance() {
    return fileUploder;
  }

  function resetItem(item) {
    Object.assign(item, {
      documenType: item.documenType || null,
      subType: null,
      hasCosts: false,
      data: {
        total: 0,
        amounts: [],
        mandatoryAmount: {
          concept: 'Minuta',
          amount: 0
        },
        totalRecal: () => {}
      },
      readyToUpload: item.readyToUpload || false,
      warningMessages: item.readyToUpload || []
    });
  }

  // Init metadata info according documents types
  fileUploder.onAfterAddingFile = function(item) {
    resetItem(item);
    vm.displayDocumentsUploader = true;
  };

  /* FileUpload Generator callbacks */

  function checkUploadItem(item) {
    if (item) return !item.readyToUpload;
    return true;
  }

  function errorCallback(item, error) {
    let errorMessage = error;
    if (error && error.msg) {
      errorMessage = `
      El documento de tipo ${getCostsDocumentType(error.msg_code.type).name} no se ha subido.
      Ya existe un documento que hace referencia al mismo procedimiento. Si quieres subir uno nuevo, elimina primero el anterior.
      `;
    }
    vm.busy = false;
    notifications.addCurrentView('error', errorMessage);
  }

  let validationArrayWatchers = [];

  function handlerChangeDocumentType(item) {
    const documentId = item.documenType.id;
    resetItem(item); // Reset posible previous data

    validationArrayWatchers.forEach(watcher => watcher()); // Destroy previous watchers
    validationArrayWatchers = [];

    let validationArray = [];

    item.readyToUpload = true;
    item.warningMessages = [];

    switch (documentId) {
      case 23: //Sentence
      case 489: //Sentence unfavourable
      case 490: //Sentence favourable
      case 491: //Sentence partially favourable
        item.readyToUpload = false;
        item.warningMessages.push('Recuerda seleccionar el tipo de sentencia');
        item.subType = '1_instancia'; // by default;

        const checkSentenceType = newVal => {
          if (newVal !== null) {
            item.readyToUpload = true;
            item.warningMessages = [];
          }
        };

        validationArray.push({
          propertyWatched: 'subType',
          ref: item,
          checkFn: checkSentenceType
        });

        break;

      case 26: //Diligencia de ordenación
      case 478: //Diligencia de ordenación
        const checkHasCosts = newVal => {
          if (newVal === true) {
            item.readyToUpload = false;
            if (!item.subType) item.warningMessages.push('Recuerda seleccionar el tipo de diligencia');
          } else {
            item.readyToUpload = true;
            item.warningMessages = [];
          }
        };

        const checkSubType = newVal => {
          if (newVal !== null) {
            item.readyToUpload = true;
            item.warningMessages = [];

            // Show info Modal with execution procedures with costs
            if (item.subType === 'ejecucion') {
              let executionProcList = [];
              Object.keys(vm.costsProcedureList).forEach(el => {
                const item = vm.costsProcedureList[el].find(a => a.costs_type_alias === 'ejecucion');
                if (item) executionProcList.push(item);
              });
              if (executionProcList.length) {
                const text = executionProcList.map(
                  el =>
                    `Costas de ejecución | ${vm.legalfile.documents.find(e => e.id === el.doc_id).comment} | ${
                      el.costs_procedure.date_created
                    }`
                );
                DialogFactoryV2.confirm({
                  title: '¿Qué quieres hacer con esta diligencia de ordenación?',
                  body:
                    '<b>Ya existen</b> los siguientes documentos de <b>diligencia de ordenación con costas de ejecución</b>. Si quieres sobreescribir uno existente, elimínalo primero y vuelve a subirlo luego.',
                  bodyAsk: text.toString().replace(/,/g, '<br>'),
                  confirmText: 'Subir nueva ejecución'
                }).then(dialogResult => {
                  if (dialogResult !== -1 && dialogResult) item.upload(); // Avoid dismiss dialog
                });
              }
            }
          }
        };

        validationArray.push(
          {
            propertyWatched: 'hasCosts',
            ref: item,
            checkFn: checkHasCosts
          },
          {
            propertyWatched: 'subType',
            ref: item,
            checkFn: checkSubType
          }
        );

        break;
      case 208: // Aprobación de costas
      case 155: // Minuta
        item.data.amounts = [];
        item.data.associatedCost = [];
        item.data.associatedProcedure = null;
        item.data.totalRecal = function() {
          item.data.total =
            (item.data.mandatoryAmount.amount || 0) + item.data.amounts.reduce((acc, i) => acc + (i.amount || 0), 0);
        };
        item.data.associateCost = function() {
          item.data.associatedProcedure = Array.isArray(item.data.associatedCost)
            ? item.data.associatedCost[0]
            : item.data.associatedCost;
        };
        item.data.checkAmountsTypes = function() {
          checkValues(true);
        };
        // Get procediments

        vm.getCostsProcedures(vm.legalfile.id, list => {
          item.data.candidatesCost = {};
          if (list && Object.keys(list).length) {
            // item.data.candidatesCost = {
            //   '1ª Instancia':
            //   '2ª Instancia':
            //   Ejecución:
            //   Incidente:
            // };
            for (const key in list) {
              if (list.hasOwnProperty(key)) {
                const element = Object.assign({}, list[key][0]);
                if (item.data.candidatesCost[element.costs_type_name]) {
                  item.data.candidatesCost[element.costs_type_name].push(element);
                } else {
                  item.data.candidatesCost[element.costs_type_name] = [element];
                }
                const tmpComment = vm.legalfile.documents.find(doc => element.doc_id === doc.id);
                element.comment = tmpComment && tmpComment.comment;
              }
            }
            return;
          }
          item.readyToUpload = false;
          item.warningMessages.push('Necesita haber algún procedimiento creado');
        });

        // Listen changes in amount on this AmountProcedure
        item.warningMessages.push('Debes añadir un tipo de costa y asociar cuantías a él.');

        function checkValues(newVal) {
          if (newVal !== null && item.data.associatedProcedure) {
            if (
              !item.data.amounts.some(i => isNaN(i.amount) || i.concept === '') &&
              item.data.mandatoryAmount.amount > 0
            ) {
              item.readyToUpload = true;
              item.warningMessages = [];
              return;
            }
          }
          item.readyToUpload = false;
          item.warningMessages = [];
          item.warningMessages.push('Recuerda rellenar los valores a las cuantías que añadas');
        }

        validationArray.push(
          {
            propertyWatched: 'total',
            ref: item.data,
            checkFn: checkValues
          },
          {
            propertyWatched: 'length',
            ref: item.data.amounts,
            checkFn: checkValues
          }
        );
        break;

      case 160:
        if (vm.legalfile.procurador === null) {
          vm.askForProcurador = true;
          notifications.addCurrentView(
            'error',
            'El expediente no tiene procurador. Por favor, elige uno en el desplegable que acaba de aparecer. Quedará grabado cuando subas el fichero.'
          );
        }
        break;
      default:
        break;
    }

    validationArrayWatchers = costsScopeValidation(validationArray);
  }

  // fileUploader CB
  function cbBeforeDocumentUpload(item) {
    item.formData = [
      {
        legalfile_id: vm.legalfile.id || 0,
        legalfile_prefix: vm.legalfile.prefix || '',
        comment: item.comment || '',
        type_id: item.documenType.id || 0,
        commandment_amount: item.data.total || 0,
        legal_costs_doc: item.hasCosts,
        due_date: item.due_date || '',
        without_due_date: item.without_due_date || '',
        lawyer_id: item.procedural_lawyer ? item.procedural_lawyer.id : '',
        procedural_phase_id: item.procedural_phase ? item.procedural_phase.id : ''
      }
    ];
    if (item.subType) item.formData[0].costs_type = item.subType;
    if ([26,478].includes(item.documenType.id) && !item.hasCosts) delete item.formData[0].costs_type;

    // For Minuta y aprobación de costas must be sent 'costs_procedure_id'
    if (item.documenType.id === 155 || item.documenType.id === 208) {
      const COST_TYPES = item.data.associatedProcedure.costs_type_alias;

      if(COST_TYPES) item.formData[0].costs_type = COST_TYPES;
      item.formData[0].costs_procedure_id =
        item.data.associatedProcedure && item.data.associatedProcedure.costs_procedure.procedure_id;
    }

    if (item.mandamientoDate) item.formData[0].mandamiento_date = item.mandamientoDate;

    if (item.selectedClaims) {
      for (let i = item.selectedClaims.length - 1; i >= 0; i--) {
        item.formData.push({ selected_claims: item.selectedClaims[i].id });
      }
    }

    if (item.selectedDocs) {
      for (let i = item.selectedDocs.length - 1; i >= 0; i--) {
        item.formData.push({ selected_docs: item.selectedDocs[i].id });
      }
    }
    vm.busy = true;
  }

  // fileUploader CB
  function cbAfterDocumentUpload(item, response) {
    const docTypeId = item.documenType.id;
    let data = {};

    switch (docTypeId) {
      case 23: // Sentencia
      case 489: //Sentence unfavourable
      case 490: //Sentence favourable
      case 491: //Sentence partially favourable
      case 478: //Sentence partially favourable
      case 26: // Diligencia de ordenacion
        data = {
          legalfile_id: vm.legalfile.id,
          document_id: response.docs_ids[0],
          prefix: vm.legalfile.real_prefix,
          costs_type_id: item.subType,
          legal_costs_doc: item.hasCosts
        };

        if (item.hasCosts) {
          // TODO: Move logic to back if it's possible
          CostsProceduresService.add(data)
            .then(() => vm.update('Documento subido con éxito'))
            .catch(error => {
              if (error.msg_code && [26,478].includes(error.msg_code.type)) {
                let diligencias = [];
                getCostsProcedures(vm.legalfile.id, list => {
                  diligencias = list.filter(item => item.costs_type_name === error.msg_code.subtype);
                });
                notifications.addCurrentView(
                  'error',
                  error.msg +
                    ` ${diligencias
                      .map(item => `Costas de ${item.costs_type_name} | ${item.costs_procedure.date_created}`)
                      .toString()}`
                );
                return;
              }
              if (error.msg) {
                notifications.addCurrentView('error', error.msg);
              } else {
                notifications.addCurrentView('error', 'Se produjo un error desconocido procesando');
              }
            });
        } else {
          vm.update('Documento subido con éxito');
        }

        break;
      case 208: // Aprobacion de costas
      case 155: // Minuta
        data = {
          legalfile_id: vm.legalfile.id,
          document_id: response.docs_ids[0],
          prefix: vm.legalfile.real_prefix,
          // costs_type_id: item.data.associatedProcedure.costs_type_id,
          costs_procedure_id: item.data.associatedProcedure.costs_procedure.procedure_id,
          costs_details: [item.data.mandatoryAmount, ...item.data.amounts],
          costs_total: item.data.total
        };

        if (item.data.associatedProcedure) {
          CostsProceduresService.update(data)
            .then(() => vm.update('Documento subido con éxito'))
            .catch(error => {
              if (error.msg) notifications.addCurrentView('error', error.msg);
            });
        }

        break;
      case 160: // Mandamiento Reclamador
      case 161: // Mandamiento cliente
      case 147: // Contestación a la demanda
        if (docTypeId === 160 && !vm.askForProcurador) {
          return;
        }
        if (docTypeId !== 161) {
          vm.legalfile.made_quantity = vm.legalfile.made_quantity || 0;
          vm.legalfile.legal_costs_amount = vm.legalfile.legal_costs_amount || 0;
        }
        vm.updateLegalFileInfo(vm.airlines);
        vm.askForProcurador = false;
        break;

      case 188:
      case 217: // Contestacion / Oposición o ???
        $http
          .post(serverAddress.getBaseUrl() + 'legalfile/update/legalfilestatechange/', {
            legalfile: vm.legalfile,
            doc_id: docTypeId
          })
          .then(vm.update);
        vm.askForProcurador = false;
        break;

      case 186:
        vm.updateLegalFileInfo(vm.airlines, true).then(vm.updateLegalFileInterests);
        break;

      default:
        vm.update('Documento subido con éxito');
        break;
    }
  }
  /* End of fileUpload generator callbacks */

  /* Costs management */
  function costsScopeValidation(validationArray = []) {
    // validationArray = [{propertyWatched, ref, checkFn}]
    return validationArray.map(item => {
      const variableWatched = item.ref ? () => item.ref[item.propertyWatched] : item.propertyWatched;
      return $scope.$watch(variableWatched, item.checkFn);
    });
  }

  function addAmountDetail(item) {
    item.data.amounts.push({
      concept: '',
      amount: 0
    });
  }

  function removeAmountDetail(item, index) {
    item.data.amounts.splice(index, 1);
    item.data.totalRecal();
  }

  function isLegalFile(documenType) {
    return !!documenType && [160, 161, 208, 155, 23, 489, 490, 491, 26, 186, 147, 478].includes(documenType.id);
  }

  function isExternalLawyerActive() {
    const items = vm.externalcourtlawyers || [];
    for (var i = 0; i < items.length; i++) {
      var lawyer = items[i];
      if (lawyer.id == vm.legalfile.externalcourtlawyer_id) {
        return lawyer.is_active;
      }
    }
    return false;
  }

  function isLawyerActive() {
    for (var i = 0; i < vm.courtlawyers.length; i++) {
      var lawyer = vm.courtlawyers[i];
      if (lawyer.id == vm.legalfile.courtlawyer_id) {
        return lawyer.is_active;
      }
    }
    return false;
  }

  function isProcuradorActive() {
    let procuradorActive = false;
    if (vm.legalfile.procurador) {
      const procurador = vm.procuradores.find(procurador => procurador.id === vm.legalfile.procurador.id);
      procuradorActive = (procurador && procurador.is_active) || false;
    }
    return procuradorActive;
  }

  function getProcedureComment(doc) {
    let comment = '';

    // Merge data for comments column on doc list
    function _mergeCommentData(docId) {
      let comment = '';
      Object.keys(vm.costsProcedureList).forEach(e => {
        const cP = vm.costsProcedureList[e].find(d => d.doc_id === docId);
        if (cP) {
          comment = cP.costs_type_name;
        }
      });
      return comment;
    }
    switch (doc.type.id) {
      case 23: // Sentence
      case 489: // Sentence unfavourable
      case 490: // Sentence favourable
      case 491: // Sentence partially favourable
      case 478: // Transferencia / emisión de mandamiento de pago
      case 26:
        let substring = `${doc.costs_type_name ? doc.costs_type_name : ''} ${doc.comment ? ' | ' + doc.comment : ''}`;
        comment = doc.legal_costs_doc ? `Con costas de ${substring}` : `${substring}`;
        break;
      case 155:
      case 208:
        comment = `${doc.type.name} ${_mergeCommentData(doc.id)} ${doc.comment ? ' | ' + doc.comment : ''}`;
        break;
      default:
        comment = doc.comment;
        break;
    }
    return comment;
  }

  function getProcedureCommentById(docId) {
    const doc = vm.legalfile.documents.find(d => d.id === docId);
    if (doc) return doc.comment;
    return '';
  }

  function changeCostsProcedureState(procedureId, procedureStateId) {
    vm.costsProceduresUpdatedList.set(procedureId, { id: procedureId, value: procedureStateId });
  }

  function showCommentOnUpdateProcedureState(item) {
    return vm.costsProceduresUpdatedList.has(item.costs_procedure.procedure_id);
  }

  function hideCommentOnUpdateProcedureState(item) {
    vm.costsProceduresUpdatedList.delete(item.costs_procedure.procedure_id);
    item.costs_procedure.costs_procedure_comment = '';
    getCostsProcedures(vm.legalfile.id, list => {
      vm.costsProcedureList = list;
    });
  }

  function updateProcedureState(procedure) {
    const procedureState = vm.costsProceduresUpdatedList.get(procedure.procedure_id);
    if (procedureState) {
      CostsProceduresService.update({
        costs_procedure_id: procedureState.id,
        costs_state_id: procedureState.value,
        costs_procedure_comment: procedure.costs_procedure_comment || ''
      })
        .then(() => {
          vm.costsProceduresUpdatedList = new Map();
          notifications.addCurrentView('info', 'Estados de los procedimientos actualizados correctamente.');
          procedure.costs_procedure_comment = '';
          vm.update();
        })
        .catch(err => notifications.addCurrentView('error', err.data));
    }
  }

  function checkButtonDisabled() {
    return vm.legalfile.prefix !== 'AE' && vm.legalfile.prefix !== 'BA';
  }

  function sendSignaturitLawsuitToClient() {
    // TODO: move this code to a reusable factory
    var claimId = vm.legalfile.claims_same_legalfile[0].id;

    // Send email to claim client with the lawsuit, to be signed via Signaturit
    $http
      .post(serverAddress.getBaseUrl() + 'airlines/' + claimId + '/send/lawsuit-with-signaturit/', {})
      .then(function(response) {
        notifications.addCurrentView('info', response.data);
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function extractLegalFileAirlineIds() {
    if (!vm.legalfile.claims_same_legalfile) return [];
    var ids = [];

    var suedAirlines = vm.legalfile.airlinesued;
    var sameLegalFileAirlines = vm.legalfile.claims_same_legalfile;

    for (var i = 0; i < suedAirlines.length; i++) {
      ids.push(suedAirlines[i].airline.id);
    }

    for (var j = 0; j < sameLegalFileAirlines.length; j++) {
      ids.push(sameLegalFileAirlines[j].airline_id);
    }

    return _uniq(ids);
  }

  function loadAirlinesued() {
    for (var i = 0; i < vm.legalfile.airlinesued.length; i++) {
      vm.airlines.push({
        id: vm.legalfile.airlinesued[i].airline.id,
        name: vm.legalfile.airlinesued[i].airline.name
      });
    }
  }

  function hasSameFlight() {
    if (!vm.legalfile.claims_same_legalfile) {
      vm.sameflight = false;
      return;
    }
    var flight = vm.legalfile.claims_same_legalfile[0].flight__id;
    for (var i = 1; i < vm.legalfile.claims_same_legalfile.length; i++) {
      if (flight !== vm.legalfile.claims_same_legalfile[i].flight__id) {
        vm.sameflight = false;
        break;
      }
    }
  }

  function loadDocxTemplates() {
    if (vm.docxtemplates.length === 0) {
      var options = {
        active: true,
        segments__id: vm.getSegmentID(vm.currentSegmentAssociated),
        target_holder: 'legalfile',
        exclude_doc_types: JSON.stringify([20, 72, 209]) // no lawsuits, agreement trial dismiss or interests doc
      };
      DocxTemplates.get(options)
        .$promise.then(data => {
          vm.docxtemplates = data.objects;
        })
        .catch(error => {
          console.error(error);
          notifications.addCurrentView(
            'error',
            'Ha habido un error cargando las plantillas procesales. Por favor, abre un ticket de soporte'
          );
        });
    }
  }

  function exportSameFlightCSV() {
    const sameFlightClaimIds = (vm.legalfile.claims_same_flight || []).map(item => item.id);
    sameFlightCSV(sameFlightClaimIds).then(function(response) {
      if (response.data === 'BIG_EXCEL_EMAIL') {
        var msg =
          'El excel que has solicitaste es demasiado grande. Por está razón, te lo hemos enviado a tu' +
          ' correo. En breve lo recibirás en tu bandeja de entrada. ¡Saludos! (^_^)/';
        notifications.addCurrentView('info', msg);
      } else {
        $window.open('/data/' + response.data, '_blank');
      }
    });
  }

  function changeClaimState() {
    var changeClaimStateOptions = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      template: require('../../../../partials/common/modals/case-change-state.html'),
      controller: 'ConfirmStateChangeCtrl'
    };

    // If we passed the second argument with a falsy value, sending_email takes 'false' value. Otherwise, is true
    vm.sendingEmail = true;
    if (arguments.length > 1 && !arguments[1]) {
      vm.sendingEmail = false;
    }

    var options = Object.assign(changeClaimStateOptions, {
      resolve: {
        legalfileId: function() {
          return vm.legalfile.id;
        },
        comment: function() {
          return vm.stateChangeComment;
        },
        newStateId: function() {
          return vm.legalfile.claims_state;
        },
        legalfilePrefix: function() {
          return vm.legalfile.prefix;
        },
        parentScope: function() {
          return vm;
        },
        sendingEmail: function() {
          return vm.sendingEmail;
        },
        client_attendance: function() {
          return vm.legalfile.client_attendance;
        }
      }
    });

    $dialog.dialog(options).open();
  }

  function changeStatus() {
    if (!vm.legalfile || !vm.legalfile.claims_state) {
      vm.displayStatusComment = true;
      vm.has_preview_email = false;
      return;
    }

    vm.displayStatusComment = true;

    vm.has_preview_email = vm.statesWithEmail.find(stateId => vm.legalfile.claims_state === stateId);

    // Juicio señalado
    vm.displayClientAttendance = vm.legalfile.claims_state === 170;
  }

  function changeClaimLawyer() {
    DialogFactory.changeClaimLawyer(vm);
  }

  function changeLawyer() {
    vm.displayLawyerChange = true;
  }

  function changeDocumentType(documentId, typeId) {
    DocumentChangeFactory.changeLegalFileDocumentType(documentId, typeId, vm.legalfile.id)
      .then(function() {
        vm.update('Documento modificado con exito');
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Se ha producido un error al cambiar el tipo de documento');
      });
  }

  function downloadDocumentDirectly(document) {
    DocumentDownloadFactory.downloadDocumentDirectly(document);
  }

  function sendDocument(document, action) {
    var promise = null;

    if (action === 'email') {
      promise = LegalFileFactory.sendLegalFileDocument(document, vm.legalfile);
    } else {
      promise = LegalFileFactory.convertLegalFileDocumentToPDF(document, vm.legalfile);
    }

    promise
      .then(function(response) {
        vm.update(response.data);
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function generateAppendix(legalfile) {
    LegalFileFactory.generateLegalFileAppendix(legalfile)
      .then(function() {
        notifications.addCurrentView(
          'info',
          'Petición de generación de anexo de documentación en proceso. ' +
            'En breve recibirás un email informándote del resultado.'
        );
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function downloadSplittedLawsuitAppendix() {
    LegalFileFactory.downloadSplittedLawsuitAppendix(vm.legalfile.id).then(function(response) {
      $window.open(response.data);
    });
  }

  function sendDocsToLawyer(legalfile) {
    if (legalfile.documents.length === 0) {
      notifications.addCurrentView('error', 'El expendiente no tiene documentación asociada');
    } else {
      LegalFileFactory.sendLegalFileDocumentsToLawyer(legalfile)
        .then(function() {
          notifications.addCurrentView(
            'info',
            'Enviado correctamente al abogado externo el recordatorio de la vista con su documentación'
          );
          $('#viewsReminder').modal('hide');
        })
        .catch(function(response) {
          notifications.addCurrentView('error', response.data);
        });
    }
  }

  function sendDocsToLegalExpert(legalfile) {
    if (legalfile.documents.length === 0) {
      notifications.addCurrentView('error', 'El expendiente no tiene documentación asociada');
    } else {
      LegalFileFactory.sendLegalFileDocumentsToLegalExpert(legalfile)
        .then(function() {
          notifications.addCurrentView(
            'info',
            'Enviado correctamente al perito el recordatorio de la vista con su documentación'
          );
        })
        .catch(function(response) {
          notifications.addCurrentView('error', response.data);
        });
    }
  }

  function openDrive(drive_key) {
    DocTools.openInDrive(drive_key);
  }

  function deleteProcess(doc, dialog) {
    const dialogResultPromise = DialogFactoryV2.confirm(dialog);

    return dialogResultPromise
      .then(dialogResult => {
        if (dialogResult === -1 || !dialogResult) return $q.reject(); // Dismiss dialog
        if (doc.type.id === 155 || doc.type.id === 208) {
          return CostsProceduresService.removeDetail(doc.id, vm.legalfile.real_prefix);
        }
        return CostsProceduresService.remove(doc.id, vm.legalfile.real_prefix);
      })
      .then(() => {
        return $http.post(serverAddress.getBaseUrl() + 'document/delete/', {
          document_id: doc.id,
          prefix: vm.legalfile.real_prefix
        }).$promise;
      });
  }

  function deleteDocument(index, doc) {
    // TODO: Some validations must be implemented on back-end also
    const dialog = {
      title: 'Confirmación de eliminación',
      body: '',
      bodyAsk: '¿Quieres eliminar el documento de todos modos?',
      confirmText: 'Confirmar'
    };

    let canBeDeleted = false;
    if ([23,489,490,491].includes(doc.type.id) && doc.legal_costs_doc) {
      dialog.body =
        'Si eliminas este documento de sentencia <b>se eliminará también el procedimiento de costas asociado al mismo y todos los datos referidos a él</b>.';
      canBeDeleted = true;
    } else if (doc.type.id === 26 && doc.legal_costs_doc) {
      dialog.body =
        'Si eliminas este documento de diligencia <b>no quedará registro del procedimiento de costas</b> asociado a ella en la pestaña costas, en el panel de costas ni en el csv.';
      canBeDeleted = true;
    } else if (doc.type.id === 478 && doc.legal_costs_doc) {
      dialog.body =
        'Si eliminas este documento de Transferencia / emisión de mandamiento de pago <b>no quedará registro del procedimiento de costas</b> asociado a ella en la pestaña costas, en el panel de costas ni en el csv.';
      canBeDeleted = true;
    }else if (doc.type.id === 155) {
      dialog.body =
        'Si eliminas este documento de minuta <b>se eliminarán también las cuantías de la tasación de costas que estaban almacenados con ella</b>.';
      canBeDeleted = true;
    } else if (doc.type.id === 208) {
      dialog.body = 'Estas seguro de que quieres eliminar el fichero ' + doc.filename;
      dialog.bodyAsk = '';
      canBeDeleted = true;
    } else {
      // default
      DialogFactory.deleteLegalFileDocument(doc, vm.legalfile, 'ConfirmDocumentDeleteCtrl');
      return;
    }
    if (canBeDeleted) {
      deleteProcess(doc, dialog)
        .then(() => vm.update('Documento eliminado con éxito'))
        .catch(error => {
          if (error && error.data) {
            notifications.addCurrentView('error', error.data.msg ? error.data.msg : error.data);
          }
        });
    }
  }

  function generateLawsuit(newDraft) {
    vm.generateLawsuitButton = false;
    var claimId = vm.legalfile.claims_same_legalfile[0].id;

    var url =
      serverAddress.getBaseUrl() +
      'legalfile/' +
      vm.legalfile.prefix +
      '/generate_' +
      vm.segment.app_name +
      '_lawsuit/';

    $http({
      url: url,
      method: 'POST',
      data: {
        legalfile_doc: true,
        claim_id: claimId,
        segment: vm.segment.app_name,
        new_draft: newDraft
      }
    })
      .then(function(response) {
        if ((/\s/).test(response.data)) {
          notifications.addCurrentView('error', response.data);
        } else {
          vm.generateAppendix(vm.legalfile);
          vm.update('Operación lanzada, recibirás un email cuando el documento esté listo :-)');
        }
        vm.generateLawsuitButton = true;
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
        vm.generateLawsuitButton = true;
      });
  }

  function hasLawsuit() {
    var lawsuit = false;

    vm.legalfile.documents.forEach(doc => {
      if (doc.type.id === 20 && !doc.deleted) {
        lawsuit = true;
      }
    });
    return lawsuit;
  }

  function inExtrajudAgreement() {
    function checkIncludes(claim) {
      return ['Acuerdo extrajudicial', 'Facturado', 'Pagado', 'Allanamiento', 'Acuerdo en Sala'].includes(
        claim.state__name
      );
    }
    return (vm.legalfile.claims_same_legalfile || []).some(checkIncludes);
  }

  function addClaimToFile(claimId) {
    LegalFileFactory.addClaimToLegalFile(vm.legalfile.id, claimId)
      .then(function() {
        vm.update('Reclamación añadida con éxito');
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function addCostsDetailToFile() {
    LegalFileFactory.addCostsDetailToLegalFile(vm.legalfile.id, vm.costsDetails)
      .then(function() {
        vm.update('Desglose de costas añadido con éxito');
        vm.costsDetails = [];
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function removeClaim(claimId) {
    LegalFileFactory.removeClaim(vm.legalfile, claimId).then(function() {
      vm.update('Reclamación eliminada del expediente');
    });
  }

  // Update state after sentence
  function sentenceClaim(claimId, sentence) {
    LegalFileFactory.sentenceClaim(claimId, sentence)
      .then(function() {
        vm.update('Reclamación actualizada');
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function updateAirlines(airlines) {
    vm.airlines = airlines;
  }

  function updateLegalFileInfo(airlines, disableUpdate) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/update/legalfileinfo/',
      method: 'POST',
      data: {
        legalfile: vm.legalfile,
        airlinessued: airlines
      }
    })
      .then(function() {
        if (!disableUpdate) vm.update('Información Procesal modificada con exito');
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function updateLegalFileInterests() {
    var data = {
      legalfile: vm.legalfile
    };
    vm.legalfile.made_quantity = vm.legalfile.made_quantity || 0;
    vm.legalfile.legal_costs_amount = vm.legalfile.legal_costs_amount || 0;

    // Specific case for BANKING
    if (vm.legalfile.prefix === 'BA') {
      data['id'] = vm.legalfile.claims_same_legalfile[0].id;
      data['assume_legal_costs'] = vm.assume_legal_costs;
    }

    $http({
      url: serverAddress.getBaseUrl() + 'legalfile/update/interests/',
      method: 'POST',
      data: data
    })
      .then(function(response) {
        var responseData = response.data;
        vm.update('Intereses modificados con exito');
        if (responseData.info) {
          notifications.addCurrentView('info', responseData.info);
        }
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function calculateLegalFileInterests() {
    $http({
      url: serverAddress.getBaseUrl() + 'legalfile/calculate/interests/',
      method: 'POST',
      data: {
        legalfile_id: vm.legalfile.id,
        made_quantity: vm.legalfile.made_quantity || 0
      }
    })
      .then(function(response) {
        var data = response.data;
        notifications.addCurrentView(
          'info',
          'Intereses calculados: ' +
            data.total_interest +
            ' -> Intereses legales: ' +
            data.legal_interest +
            ' + Intereses moratorios:  ' +
            data.moratory_interest
        );
        vm.legalfile.legal_interests_amount = data.total_interest;
      })
      .catch(function(response) {
        notifications.addCurrentView('error', 'ERROR: ' + response.data);
      });
  }

  function uncertainAmount() {
    if (vm.legalfile.legal_costs_uncertain_amount === true) {
      vm.legalfile.legal_costs_certain_amount = 0;
    }
  }

  function calculateLegalFileCosts() {
    // TODO: Tasar costas por procedimiento para AE
    $http({
      url: serverAddress.getBaseUrl() + 'legalfile/calculate/legalcosts/',
      method: 'POST',
      data: {
        legalfile_id: vm.legalfile.id,
        legal_costs_certain_amount: vm.legalfile.legal_costs_certain_amount || 0,
        legal_costs_uncertain_amount: vm.legalfile.legal_costs_uncertain_amount,
        trial_type: vm.legalfile.trial_type,
        procesal_stage: vm.legalfile.procesal_stage
      }
    })
      .then(function(response) {
        var data = response.data;
        notifications.addCurrentView('info', 'Costas calculadas: ' + data.legal_costs_amount + '€');
        vm.legalfile.legal_costs_amount = data.legal_costs_amount;
      })
      .catch(function(response) {
        notifications.addCurrentView('error', 'ERROR: ' + response.data);
      });
  }

  function changeCourt() {
    vm.legalfile.court = null;
    vm.displayChangeCourt = true;
  }

  function generateModelPDF(typeModel) {
    let u, d;

    if (vm.legalfile.autos === null) {
      // No autos number. Document can't be generated
      notifications.addCurrentView(
        'error',
        'El expediente no tiene número de autos. No se puede generar el documento.'
      );
      return;
    }
    // No procedural doc and autos number exist. Can generate the document
    u = serverAddress.getBaseUrl() + 'legalfile/generate-model-pdf/' + typeModel;
    d = {
      legalfile: vm.legalfile,
      model_type: typeModel
    };

    // Now send the request
    notifications.clear();
    notifications.addCurrentView('info', 'Generando documento... Por favor, espere');
    $http({
      url: u,
      method: 'POST',
      data: d
    })
      .then(function(response) {
        var data = response.data;
        notifications.clear();
        if (!data.drive) {
          var pom = $document[0].createElement('a');
          pom.setAttribute('href', 'data:application/pdf,' + encodeURIComponent(data.response));
          pom.setAttribute('download', data.filename);
          pom.click();
          pom.remove();
        }

        if (data.alert_banner) {
          notifications.addCurrentView('info', data.alert_banner);
        } else {
          notifications.addCurrentView('success', 'Modelo ' + data.filename + ' creado correctamente :)');
        }
        vm.update();
      })
      .catch(function(error) {
        notifications.clear();
        notifications.addCurrentView('error', error.data);
      });
  }

  function generateFromDocxTemplate(template) {
    if (!vm.legalfile.claims_same_legalfile) {
      notifications.addCurrentView('error', 'Se ha producido un error generando el modelo.');
      return;
    }
    notifications.clear();
    notifications.addCurrentView('info', 'Generando modelo... Por favor, espere');
    $http({
      url: serverAddress.getBaseUrl() + 'legalfile/generate_procedural_doc',
      method: 'POST',
      data: {
        claim_id: vm.legalfile.claims_same_legalfile[0].id,
        segment: vm.segment.app_name,
        legalfile_doc: vm.legalfile.prefix !== 'IM',
        legalfile_unpaid_doc:  vm.legalfile.prefix === 'IM',
        template_id: template.id,
        target_doc_type_id: template.target_doctype_id,
        new_draft: 1
      }
    })
      .then(function() {
        notifications.clear();
        notifications.addCurrentView(
          'success',
          'Operación lanzada, recibirás un email cuando el documento esté listo :-)'
        );
      })
      .catch(function(error) {
        notifications.clear();
        notifications.addCurrentView('error', error.data);
      })
      .finally(() => {
        vm.update();
      });
  }

  function processTrialCity() {
    const OTHERS_DECIDES_CITIES = [
      93, // Castellón de la plana
      17, // Alicante
      1 // A Coruña
    ];

    if (OTHERS_DECIDES_CITIES.indexOf(vm.legalfile.trial_municipality.id) !== -1) {
      notifications.addCurrentView(
        'error',
        'Para ' + vm.legalfile.trial_municipality.name + ' el lugar del juicio debe ser el de la parte demandada.'
      );
      return;
    }

    getDefaultsbyTrialCity()
      .then(response => {
        var data = response.data;

        if (!vm.legalfile.procurador) {
          vm.legalfile.procurador = {};
        }

        // Sin Procurado for airlines
        if (!vm.legalfile.claims_same_legalfile[0].bought_portfolio__business_name)
        {
          vm.legalfile.procurador.id = ['AE', 'TA'].indexOf(vm.legalfile.prefix) != -1 ? 34 : data.procurador_id;
        }
        vm.legalfile.courtlawyer_id = data.lawyer_id;
        vm.legalfile.externalcourtlawyer_id = data.externalcourtlawyer_id;
      });
  }

  function getDefaultsbyTrialCity() {
    var airlinesNoProc = [250, 650, 539];
    var noProc = 0;
    var municipality_id = vm.legalfile.trial_municipality.id === 297 ? 406 : vm.legalfile.trial_municipality.id; // Pontevedra con Sede en Vigo ? Vigo : trial_municipality

    if (vm.legalfile.airlinesued) {
      noProc = vm.legalfile.airlinesued.some(e => e.airline && e.airline.id && airlinesNoProc.indexOf(e.airline.id) !== -1);
      noProc = noProc ? 1 : 0;
    }

    return $http.get(serverAddress.getBaseUrl() + 'legalfile/defaults-by-trialcity/', { params: {
      trial_municipality_id: municipality_id,
      amount: vm.legalfile.claimed_total,
      noproc: noProc,
      prefix: vm.legalfile.real_prefix
    }});
  }

  function formatPhoneNumber(phoneNumber) {
    var numbers = '';

    if (phoneNumber){
      var strippedNumber = removeSpacesPhone(phoneNumber);
      numbers = strippedNumber ? strippedNumber.split('/').map(function(number) {
        return number.trim();
      }) : '';
    }
    
    return numbers;
  }

  function getPhoneNumbersForExtCourtLawyer() {
    if(vm.legalfile && vm.legalfile.externalcourtlawyer && vm.legalfile.externalcourtlawyer.phone_number){
      return formatPhoneNumber(vm.legalfile.externalcourtlawyer.phone_number);
    }
    return [];
  }
  
  function getPhoneNumbersForProcurador() {
    if(vm.legalfile && vm.legalfile.procurador && vm.legalfile.procurador.phone_number){
      return formatPhoneNumber(vm.legalfile.procurador.phone_number); 
    }
    return [];
  }
  
  function getPhoneIsValid(phone) {
    var regex = /^(\+34|0034|34)?[6789]\d{8}$/;
    return regex.test(phone);
  }

  function addCommentSucessCb() {
    vm.update('Comentario añadido con exito');
  }

  // DOCUMENT MANAGER TEMP
  $scope.$on('document:deleted', (e, doc) => {
    vm.busy = false;
    vm.update(`Documento ${doc.filename} borrado`);
  });

  $scope.$on('document:download', (e, doc) => {
    downloadDocumentDirectly(doc);
  });

  // click on document to load viewer
  $scope.$on('viewer:loadDoc', (e, doc) => {
    initViewer(doc);
  });

  // document-viewer request new document
  $scope.$on('viewer:changeDoc', (e, doc) => {
    loadDocument(doc);
  });

  // document-viewer request group change
  $scope.$on('viewer:changeGroup', (e, group) => {
    setDocumentGroup(group);
    loadDocument(vm[group.objsKey][0]);
  });

  // document-viewer request closing
  $scope.$on('viewer:close', () => {
    _resetViewer();
  });

  /* Change active document group */
  function setDocumentGroup(documentGroup) {
    if(vm.activeDocumentGroup == documentGroup) return;
    vm.activeDocumentGroup = documentGroup;
    vm.activeGroupDocs = vm[vm.activeDocumentGroup.objsKey].filter(d => !d.is_drive);
  }

  $scope.$watch('vm.legalfile.documents', docs => {
    if(!docs) return;

    vm.legalfilesProcessed = docs.filter(d => !d.deleted && !d.is_missing);
    _updateDoc();
  });

  $scope.$watch('vm.legalfile.pending_validation_documents', docs => {
    if(!docs) return;

    vm.unvalidatedDocuments = docs.filter(d => !d.deleted && !d.is_missing);
    _updateDoc();
  });

  $scope.$watch('vm.legalfile.sentences_same_flight', docs => {
    if(!docs) return;

    vm.sentencesProcessed = docs.filter(d => !d.deleted && !d.is_missing);
    _updateDoc();
  });

  function _updateDoc() {
    _updateDocumentGroups();

    if(!vm.activeDocument) return;
    let newGroup = _getDocGroup(vm.activeDocument);
    if(newGroup != vm.activeDocumentGroup) setDocumentGroup(newGroup);
  }

  /* Filter avaliable document groups, with the ones with documents */
  function _updateDocumentGroups() {
    vm.availableDocumentGroups = vm.documentGroups.filter(g => vm[g.objsKey] && vm[g.objsKey].length);
  }

  /* Returns the first document from the first active document group */
  function _getInitialDoc() {
    return vm[vm.availableDocumentGroups[0].objsKey][0];
  }

  /* Get the first document group who contains the passed element */
  function _getDocGroup(doc) {
    for (var i = vm.availableDocumentGroups.length - 1; i >= 0; i--) {
      if(vm[vm.availableDocumentGroups[i].objsKey].find(d => d.id == doc.id)) return vm.availableDocumentGroups[i];
    }
  }

  function initViewer(initialDoc) {
    if (!initialDoc) initialDoc = _getInitialDoc();
    $scope.$broadcast('viewer:init');
    setDocumentGroup(_getDocGroup(initialDoc));
    loadDocument(initialDoc);
  }

  function _resetViewer() {
    $scope.$broadcast('viewer:remove');
    vm.activeDocument = null;
  }

  // load new document for the viewer
  function loadDocument(doc) {
    vm.activeDocument = doc;
  }

  function getDocumentGroup(key) {
    return vm.documentGroups.find(g => g.key == key);
  }

  // ***************************
  // Validate documents section
  // ***************************

  const validationUploader = {
    fillItemData: cbBeforeDocumentUpload,
    checkUploadItem,
    sucessCallback: cbAfterDocumentUpload,
    errorCallback,
    testChangeDocumentType: handlerChangeDocumentType
  };

  vm.isReadyForValidation = () => {
    validationUploader.checkUploadItem(vm.validateDetails);
  };

  function validateDocumentSubmit(index, prefix, documentId, payload) {
    ValidationService.validateDocument(prefix, documentId, payload)
      .then(response => {
        // To adapt the validation with existing code in cbAfterDocumentUpload, the creation of this variable in the response must be forced.
        response.docs_ids = [];
        response.docs_ids[0] = documentId;

        validationUploader.sucessCallback(vm.validateDetails, response);
        toggleValidatedModal('hide', index);
      })
      .catch(response => {
        validationUploader.errorCallback(null, response.data);
      });
  }

  function validateDocument(document, index) {
    vm.validateDetails = {
      commandmentAmount: document.commandment_amount,
      comment: document.comment,
      data: {},
      documenType: document.type,
      due_date: document.due_date,
      hasCosts: document.legal_costs_doc,
      procedural_lawyer: document.lawyer,
      procedural_phase: document.procedural_phase,
      procuratorDocument: document,
      subType: document.costs_type_name,
      without_due_date: document.without_due_date,
      documentId: document.id,
      readyToUpload: false
    };

    vm.isReadyForValidation();
    validationUploader.testChangeDocumentType(vm.validateDetails);

    toggleValidatedModal('show', index);
  }

  function confirmValidateDocument(index) {
    validationUploader.fillItemData(vm.validateDetails);

    const resourceNameBySegment = {
      'AE': 'airline_legalfile_document',
      'BA': 'bank_legalfile_document',
      'AC': 'accident_legalfile_document',
      'TE': 'phone_legalfile_document',
      'LA': 'laboral_legalfile_document',
      'NE': 'negligences_legalfile_document',
      'SO': 'so_legalfile_document',
      'TA': 'municipaltax_legalfile_document'
    };

    const currentPrefix = resourceNameBySegment[vm.legalfile.prefix] || '';

    const costsTypeAlias = vm.validateDetails.data.associatedProcedure
      ? vm.validateDetails.data.associatedProcedure.costs_type_alias
      : null;

    const procedureId =
      vm.validateDetails.data.associatedProcedure &&
      vm.validateDetails.data.associatedProcedure.costs_procedure
        ? vm.validateDetails.data.associatedProcedure.costs_procedure.procedure_id
        : null;

    validateDocumentSubmit(index, currentPrefix, vm.validateDetails.documentId, {
      commandment_amount: vm.validateDetails.data.total,
      costs_type: vm.validateDetails.subType || costsTypeAlias,
      legal_costs_doc: vm.validateDetails.hasCosts,
      costs_procedure_id: procedureId
    });
  }

  function toggleValidatedModal(action, index) {
    const modalId = '#validateModal' + index;

    if (action === 'show') {
      vm.showPartialDetails = true;
      $(modalId).modal('show');
    }

    if (action === 'hide') {
      vm.showPartialDetails = false;
      $(modalId).modal('hide');
    }
  }

  function displayProcuratorComment(document) {
    document.showProcuratorComment = !document.showProcuratorComment;
  }
}
