NotificationsProceduralControlListCtrl.$inject = [
  '$scope',
  '$controller',
  '$window',
  'LegalfilesConstants',
  'dataLoader',
  'UtilsFactory'
];

export default function NotificationsProceduralControlListCtrl(
  $scope, $controller, $window, LegalfilesConstants, dataLoader, UtilsFactory
) {
  const vm = this;

  const INITIAL_STATE = {
    loadingFilters: true,
    proceduralLawyers: [],
    documentTypes: [],
    settings: {
      title: 'Filter list unnamed',
      enableFiltering: true,
      displaycolumnsFilters: false,
      resourceName: 'legalfile_documents_count',
      resourceNameBySegment: {},
      pageSize: '20',
      columnDef: LegalfilesConstants.defaultColumnDef_NotificationsProceduralControl,
      filterFieldInfo: LegalfilesConstants.filterFieldInfo_NotificationsProceduralControl
    },
    segmentToFilter: 'ALL',
    hideIMOptionSegmentToFilter: true
  };

  const METHODS = {
    getExtraFilterParams,
    navigateToNotificationList,
    getFilterParams
  };

  /**
  * Override extra filters to add segment as a query parameter.
  **/
  function getExtraFilterParams(){
    return {'segment': vm.segmentToFilter !== 'ALL' ? vm.segmentToFilter : '', 'group_by': 'type'};
  }

  function getFilterParams() {
    let currentFilters = UtilsFactory.reduceObject(vm.currentFilters, 'name');
    currentFilters = UtilsFactory.formatObjectWithNullValue(currentFilters);
    return currentFilters;
  }

  function navigateToNotificationList(filters){
    const searchFilters = {
      ...vm.getFilterParams(), // Add filters selected by the user
      ...filters, // Filters based on row and column clicked
      'segment': vm.segmentToFilter
    };
    const path = '/#/legalfiles/management-notifications/?' + UtilsFactory.objectToQueryString(searchFilters);
    $window.open(path, '_blank');
  }

  $controller('TastypieBaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS);

  async function populateFilters() {
    try {
      const filters = await dataLoader(['proceduralLawyers', 'legalfileDocumentTypes']).$promise;
      vm.proceduralLawyers = filters['proceduralLawyers'].objects;
      vm.documentTypes = filters['legalfileDocumentTypes'].objects;
      vm.loadingFilters = false;
      $scope.$apply();
    } catch (error) {
      console.error('Error loading filters:', error);
    }
  }

  populateFilters();


  /** Initiate List! */
  vm.initResource();
}
