import angular from 'angular';
import moment from 'moment';
import claimKeysDict from '../../data/claimKeysDict.json';

/**
* Base detail controller ______________
* Provides common methods to all claim detail controllers.
* It's called from a parent claim controller and that is expanded as vm
* Most of the methods in baseDetailCtrl are based on a claim defined in vm
* as vm.claim and require some segment configuration as vm.DJANGO_MODEL or vm.SEGMENT_PREFIX
*
* Claim interface ____________
* The most important piece from baseDetailCtrl is an interface build around a claim resource.
* The interface provides the methods to initiate, load and save the claim and the hooks to
* manipulate it's data in a horizontal way. The main methods ar:
*
* - vm.claimDetail.init
*   initiate the provider, load initial data and launch getClaim
*
* - vm.claimDetail.update (getClaim)
*   request the claim and update enviroment
*
* - vm.claimDetail.save
*    saves the claim
*
* - vm.claimDetail.reset
*   restores the latest data requested with getClaim
*
* The required data to load the provider is:
*   vm.DJANGO_MODEL & vm.MODEL_PROVIDER => two names for the sHame thing (TODO refactor)
*   vm.SEGMENT_PREFIX => two letter vertical identifier
*   vm.claim_id => the id of the entity. Comes from the route or breaks
*
* Optionally we can have:
*   vm.CLAIM_DICTIONARIES => an object of dataloader resources that will be loaded asynchronaly
*   vm.initClaimCB => callback called only once, the first time the claim is loaded
*   vm.postRefreshClaimCB => callback called every time the claim is loaded
*   vm.preSaveClaimCB => callback called just before callin the save method
*   vm.prepareClaimData => method to proccess the claim data once arrives and before is saved in the scope
*
* Finally we have some events to call the interface methods from componenents
* without directly accessing the data
*   claim:reset'
*   claim:save'
*   claim:refresh'
*   claim:update'
*   claim:finish'
*   legalfile:save'
*
*/
BaseDetailCtrl.$inject = [
  '$scope',
  '$http',
  '$route',
  '$routeParams',
  '$filter',
  '$injector',
  '$location',
  '$q',

  // our dependencies
  'vm',
  'dataLoader',
  'serverAddress',
  'notifications',
  'ClaimFactory',

  'LegalFileFactory',
  'constantsFactory',
  'select2Factory',
  'ERPmanager',

  'SalesClaimState',
  'ClaimState',
  'InvestorPortfolio',
  'BoughtPortfolio'
];

export default function BaseDetailCtrl(
  $scope,
  $http,
  $route,
  $routeParams,
  $filter,
  $injector,
  $location,
  $q,
  vm,
  dataLoader,
  serverAddress,
  notifications,
  ClaimFactory,
  LegalFileFactory,
  constantsFactory,
  select2Factory,
  ERPmanager,
  SalesClaimState,
  ClaimState,
  InvestorPortfolio,
  BoughtPortfolio
) {
  // private
  let _provider;

  const INITIAL_STATE = {
      // import general layout settings => TODO put it in a base-base.controller.js
      layoutSettings: $route.current.$$route.layoutSetting,
      segmentID: vm.SEGMENT_ID,

      initialTab: $location.hash() || undefined,
      tabConfig: [],

      // required
      MODEL_PROVIDER: null,

      claim_id: $routeParams.id || null,
      claim: null,
      userClaims: null, // user claims
      legalfileinfo: null,

      claimLoadError: null,

      busy: false,
      assigned_to_portfolio_date: null,
      assigned_to_portfolio: false,

      VERBOSE_NAME: 'Reclamación',

      documentsResponse: [], // full document endpoint response
      documents: [], // filtered general documents
      documentation_analysis: [],
      documentTypes: [],
      clientDocumentTypes: [],
      fileDocumentTypes: [], // only for banking,
      statesWithEmail: [],

      // keep track of tabs already loaded
      loadedFullHistory: false,
      loadedSameUserClaims: false,
      loadedDocuments: false,

      dateOptions: constantsFactory.getDateOptions()
    },

    METHODS = {
      // public interface
      claimDetail: {
        init: bootstrapClaim,
        update: getClaim,
        save: saveClaim,
        reset: discardClaimChanges
      },
      getClaim, // just for testing
      finishClaim,
      claimChanges: [],
      legalfileChanges: [],

      // optional
      initClaimCB: null,
      postRefreshClaimCB: null,
      preSaveClaimCB: null,
      prepareClaimData: null,

      setEntityData,
      ERPmanager,

      parseDocuments,
      getDocumentTypes,

      saveLegalFile,
      generateLegalFile,
      deleteLegalFile,

      getSalesStatesForSegment,
      getDictionaries,
      prepareDictionaries,
      addCommentSucessCb,
      getClaimStatesForSegment,
      getDetailTypeLiteral,
      isManagementServices,
      getInvestorsForSegment,
      getBoughtPortfolioForSegment,
      generateFromDocxTemplate,

      // tab stuff
      setTabs,
      fetchHistoryChanges,
      historyChanges: [],
      fetchSameUserClaims,
      fetchDocuments,
      fixIdFromHash,
      savePortfolioAssignment,
      removeInvestorAsignment,

      parseDates,
      auxTabSelect: (tabIndex, fn) => {
        $location.hash(tabIndex);
        if (Array.isArray(fn)) fn[0].apply(undefined, fn[1]);
        else if(fn) fn.call();
      },
      logGetClaimError
    };

  Object.assign(vm, INITIAL_STATE, METHODS, select2Factory);

  /*********************** BASEDETAIL INTERFACE *******************/
  function bootstrapClaim() {
    if (!vm.DJANGO_MODEL || !vm.MODEL_PROVIDER || !vm.claim_id || !vm.SEGMENT_PREFIX) {
      throw new Error('Unable to initiate claim without required configuration.');
    }
    vm.claim_id = fixIdFromHash(vm.claim_id);

    initProvider();
    initClaimDetailEvents();
    initClaimDetailWatchers();

    // update tabs navigation on locationChanges not made by the user (browser back & froward buttons mostly)
    $scope.$on('$locationChangeStart', function() {
      if($location.hash() && vm.initialTab !== $location.hash()) {
        vm.initialTab = $location.hash();
      };
    });

    vm.prepareDictionaries(vm.CLAIM_DICTIONARIES);

    const promises = [
      vm.getClaim(null, true),
      vm.getDictionaries(vm.CLAIM_DICTIONARIES)
    ];

    // I think dictionaries request almost always ends up before claim, but anyway
    $q.all(promises)
      .then(() => {
        if (vm.initClaimCB) vm.initClaimCB();

        if(vm.initialTab == 'historial') vm.fetchHistoryChanges();
        if(vm.initialTab == 'vuelo') vm.prepareFlightTab();
        if(vm.initialTab == 'mismo-cliente') vm.fetchSameUserClaims();
        if(vm.claim.transfered_to_investor_date === null) {
          getInvestorsForSegment();
        }
        if(vm.claim.bought_date === null) {
          getBoughtPortfolioForSegment();
        }
        vm.busy = false;
      })
      .catch(logGetClaimError);

  };

  function initClaimDetailEvents() {
    $scope.$on('claim:reset', discardClaimChanges);
    $scope.$on('claim:save', saveClaim);
    $scope.$on('claim:refresh', getClaimEvt);
    $scope.$on('claim:update', saveClaimEvt);
    $scope.$on('claim:updateFull', saveClaimFullEvt);
    $scope.$on('claim:finish', finishClaim);
    $scope.$on('legalfile:save', saveLegalFile);

    $scope.$on('claim:setActiveTab', setActiveTab);
  }

  function initClaimDetailWatchers() {
    $scope.$watch('vm.claim', processClaimChanges, true); // expensive stuff, danger!
    $scope.$watch('vm.legalfileinfo', processLegalfileChanges, true);
  }

  function getClaim(msg, ignoreDocs) {
    let promise = _provider.get({id: vm.claim_id}).$promise;

    vm.busy = true;

    promise
      .then(response => {
        updateClaimScope(response);

        // Always get document except the first time the claim is loaded
        if(ignoreDocs && vm.initialTab !== 'documentacion') {
          _getClaimCb(msg, response);
          return;
        }

        vm.fetchDocuments(true)
          .then(() => {
            _getClaimCb(msg, response);
          })
          .catch(() => {
            notifications.addCurrentView(
              'error',
              `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`
            );
          });
      })
      .catch(logGetClaimError);
    return promise;
  }

  function getClaimEvt(e, data) {
    vm.getClaim(data ? data.msj : null);
  }

  // prepare provider response data and update enviroment
  function updateClaimScope(response) {
    // apply response processor hook if available
    vm.claim = vm.prepareClaimData ? vm.prepareClaimData(response) : response;
    vm.initialClaimState = angular.copy(vm.claim);
    if (vm.claim.investor){
      vm.assigned_to_portfolio = true;
    }else{
      vm.assigned_to_portfolio = false;
    }
    if (vm.claim.bought_portfolio){
      vm.bought_portfolio = true;
      vm.bought_portfolio_business_name = vm.claim.bought_portfolio.business_name;
      vm.bought_portfolio_client_type = vm.claim.bought_portfolio.client_type;
    }else{
      vm.bought_portfolio = false;
    }

    // keep legalfileinfo and history away from claim. Maybe we should deep copy it
    if(vm.claim.legalfile) {
      vm.legalfileinfo = vm.claim.legalfile;
    }
    if(!vm.loadedFullHistory && vm.claim.changes) vm.historyChanges = vm.claim.changes;
    vm.loadedFullHistory = false;

    vm.claimLoadError = null;

    // entitydata just 4 editable-document => TODO: KILL
    vm.entityData = {
      claim_id: vm.claim.id,
      legalfile_id: vm.legalfileinfo === null ? null : vm.legalfileinfo.id,
      prefix: vm.SEGMENT_PREFIX
    };
  }

  function logGetClaimError(e) {
    if (e['data'] && e['data'] == 'No tienes permisos para ver reclamaciones de usuarios cancelados.'){
      notifications.addCurrentView('error', e['data']);
      vm.viewCancelledClientPermission = false;
      vm.claim.client.user.is_active = false;
    } else{
      notifications.addCurrentView(
        'error',
        `Se produjo un error cargando la ${vm.VERBOSE_NAME.toLowerCase()} con id ${vm.claim_id}. Contacta con el equipo de Tecnología`
      );
      vm.claimLoadError = e;
    }

    console.error(e);
    vm.busy = false;
  };

  function _getClaimCb(msg, response) {
    if (vm.postRefreshClaimCB) vm.postRefreshClaimCB(response);
    vm.setTabs();
    // empty strig => don't notify
    if (msg && typeof msg == 'string') {
      notifications.addCurrentView('success', msg);
    }
  }

  // performa update request to the claimProvider.
  // When data is passed, it will use it as the claim data instead as vm
  function _saveClaim(data, qParams) {
    let providerCfg = {};
    providerCfg.id = vm.claim_id;
    if (qParams) Object.assign(providerCfg, qParams);

    if (vm.preSaveClaimCB) vm.preSaveClaimCB();

    return _provider.update(providerCfg, data ? data : vm.claim).$promise;
  }

  // public method to save claim
  function saveClaim(qParams, msg, noRefresh, msgErr) {
    vm.busy = true;

    let promise = _saveClaim(null, qParams);

    promise
      .then(response => {
        updateClaimScope(response);
        if (!noRefresh) vm.getClaim(msg || `${vm.VERBOSE_NAME} actualizada con éxito`);
      })
      .catch(() => {
        notifications.addCurrentView('error', msgErr || 'No pudo guardarse la reclamación');
        vm.busy = false;
      });

    return promise;
  }

  function saveClaimFullEvt(e, claim) {
    if(!claim) return;
    vm.busy = true;

    _saveClaim(claim)
      .then(response => {
        $scope.$broadcast('claim:updatedFull');
        updateClaimScope(response);
        if (vm.postRefreshClaimCB) vm.postRefreshClaimCB();
        notifications.addCurrentView('info', 'Reclamación actualizada correctamente');
      })
      .catch(e => {
        $scope.$broadcast('claim:updateFull:error', e);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  // SaveClaim called as an event from a component
  function saveClaimEvt(e, data) {
    if(!data || !data.fields) return;
    vm.busy = true;

    const KEYS = Object.keys(data.fields);
    if (!KEYS.length) {
      console.log('Actualización solicitada sin ningún dato a actualizar');
      return;
    }
    let claim = angular.copy(vm.claim);
    KEYS.forEach(function (e) { claim[e] = data.fields[e]; });

    _saveClaim(claim)
      .then(response => {
        $scope.$broadcast('claim:updated', data);
        updateClaimScope(response);
        if (vm.postRefreshClaimCB) vm.postRefreshClaimCB();

        const KEYS_ES = KEYS.map(e => { return claimKeysDict[e] ? claimKeysDict[e] : e; });
        const MSJ = `${KEYS.length == 1 ? 'Campo' : 'Campos'} ${KEYS_ES.join(', ')} de la reclamación actualizado${KEYS.length == 1 ? '' : 's'}.`;
        notifications.addCurrentView('info', MSJ);
      })
      .catch(e => {
        $scope.$broadcast('claim:update:error', data, e);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function initProvider() {
    // sensitive stuff, let0s try and catc
    try {
      _provider = $injector.get(vm.MODEL_PROVIDER);
    } catch (e) {
      throw new Error('Unable to initialice provider ' + vm.MODEL_PROVIDER);
    }
  }

  function discardClaimChanges() {
    vm.claim = angular.copy(vm.initialClaimState);
  }

  /*********************** OTHER FUNCTIONS *******************/
  function getDictionaries(dictionariesMap) {
    /**
     * Prepare a dictionary of request and send the to Dataloader
     *
     * Each key in the dictionary will be the name of a variable created
     * in vm and filled with the dataloader response
     * The value for the key can be:
     * - Just a string for the dataloader provider
     * - A configuration object with this fields:
     *    - entity: the dataloader provider
     *    - data [optional]: data passed directly to the dataloader
     *    - key [optional]: key from the response where the data is ('objects' by default)
     *    - postProcess [optional]: callback to process the response deta
     *
     *  const dictionariesMap = {
     *    users: {
     *      entity: 'taxeslawyers',
     *      data: { type: 'taxes' }
     *    },
     *    users: {
     *      entity: 'taxeslawyers',
     *      key: 'items',
     *      postProcess: function(items) { return items }
     *    },
     *    salesman: 'taxessalesmen',
     *    sources: 'claimsources',
     *    claimTypes: 'claimtypes',
     *    provinces: 'provinces'
     *  };
     */

    const requestDataObject = {};

    Object.keys(dictionariesMap).forEach(e => {
      if (typeof dictionariesMap[e] === 'object' && dictionariesMap[e].hasOwnProperty('data')) {
        requestDataObject[dictionariesMap[e].entity] = { requestData: dictionariesMap[e].data };
      }
    });

    const entities = [];
    Object.values(dictionariesMap).forEach(e => {
      if (typeof e === 'string') entities.push(e);
      else if (e.hasOwnProperty('entity')) entities.push(e.entity);
      else throw new Error('Invalid dataLoader config', e);
    });

    const p = dataLoader(entities, requestDataObject).$promise;

    p
      .then(response => {
        Object.keys(dictionariesMap).forEach(d => {
          const entity = dictionariesMap[d];

          if (typeof entity === 'string') {
            vm[d] = response[entity].objects;
          } else {
            if(entity.key) { // allows a custom key instead of classic tastypie 'objects'
              vm[d] = response[entity.entity][entity.key];
            } else {
              vm[d] = response[entity.entity].objects;
            }

            if(entity.postProcess) vm[d] = entity.postProcess(vm[d]);
          }
        });
      })
      .catch(err => console.error(err));
    return p;
  }

  // create dictionaries variables so they don't throw undefined errrors
  function prepareDictionaries(dictionariesMap) {
    Object.keys(dictionariesMap).forEach(e => {
      vm[e] = [];
    });
  }

  function getClaimStatesForSegment() {
    vm.claimStates = ClaimState.get({ query: '&segments__id=' + vm.SEGMENT_ID });
  }

  function getInvestorsForSegment() {
    InvestorPortfolio.get({ query: '&allowed_segments_for_investors__id=' + vm.SEGMENT_ID }).$promise.then(
      function(response) {
        vm.investorPorfoliosList = response.objects;
      });
  }

  function getBoughtPortfolioForSegment() {
    BoughtPortfolio.get({ query: '&allowed_segments_for_bought_portfolio__id=' + vm.SEGMENT_ID }).$promise.then(
      function(response) {
        vm.boughtPortfoliosList = response.objects;
      });
  }

  function getSalesStatesForSegment() {
    vm.saleStates = SalesClaimState.get({ query: '&segments__id=' + vm.SEGMENT_ID });
  }

  function setEntityData(sourceEntity, overrideFrom) {
    vm.entityData = {
      prefix: (overrideFrom.prefix || sourceEntity.prefix),
      claim_id: (overrideFrom.claim_id || sourceEntity.claim_id),
      legalfile_id: (overrideFrom.legalfile_id || sourceEntity.legalfile_id)
    };
  }

  // Updates dinamic data from claim tabs settings
  // As every claim type uses the same data, we put everything together based on tab name
  function setTabs() {
    if(!vm.tabConfig) throw new Error('Required tab config not found');

    let newSettings = [...vm.tabConfig];

    for (var i = newSettings.length - 1; i >= 0; i--) {
      switch(newSettings[i].heading) {
        case 'Documentación':
          let docCount = 0;

          if(vm.legalfile) { // expediente
            docCount = Number(vm.legalfile.documents.length) + Number(vm.legalfile.pending_validation_documents.length);
          } else if(vm.flight_documents) {
            docCount = Number(vm.claim.documents_count + vm.flight_documents.length);
          } else {
            docCount = Number(vm.claim.documents_count);
          }

          newSettings[i].select = vm.fetchDocuments;
          newSettings[i].headingBadge = docCount;
          break;

        case 'Historial':
          if(!vm.legalfile) {
            newSettings[i].select = vm.fetchHistoryChanges;
          }
          break;

        case 'Vuelo':
          newSettings[i].headingBadge = calculateBadgeForVueloTab();
          newSettings[i].select = vm.prepareFlightTab;
          break;

        case 'Mismo vuelo':
          let sameFlight = 0;

          if(vm.legalfile) { // expediente
            sameFlight = vm.legalfile.claims_same_flight && Number(vm.legalfile.claims_same_flight.length);
          } else {
            sameFlight = Number(vm.claim.claims_same_flight.length);
          }

          newSettings[i].headingBadge = sameFlight;
          break;

        case 'Mismo expediente':
          newSettings[i].headingBadge = Number(vm.claim.claims_same_legal_file.length);
          break;

        case 'Mismo cliente':
          newSettings[i].select = vm.fetchSameUserClaims;
          newSettings[i].headingBadge = Number(vm.claim.number_of_claims_same_client) - 1;
          break;

        case 'Invitaciones':
          newSettings[i].headingBadge = vm.claim.accepted_invitations ? vm.claim.accepted_invitations.valid.length +
            vm.claim.accepted_invitations.accepted.length +
            vm.claim.accepted_invitations.not_accepted.length : 0;
          break;

        case 'Reclamaciones': // legalfiles
          newSettings[i].headingBadge = vm.legalfile.claims_same_legalfile ? Number(vm.legalfile.claims_same_legalfile.length) : 0;
          break;
      }
    }

    vm.tabConfig = newSettings;
  }

  function calculateBadgeForVueloTab() {
    const flightStatus = vm.claim.flight ? vm.claim.flight.status_msg : 'Desconocido';
    const flight = vm.claim.flight ? vm.claim.flight.delay : 0;
    const distance = vm.claim.flight ? vm.claim.flight.distance : 0;
    const flightDelayInMinutes = $filter('minutesToDays')(flight);
    return `${flightStatus} - ${flightDelayInMinutes} - ${distance}km`;
  }

  function fetchHistoryChanges(refresh = false) {
    if (vm.loadedFullHistory && !refresh) return;

    var targetUrl = serverAddress.getBaseUrl() + 'common/claim/fetch-claim-full-history-changes/';

    if (vm.claim) {
      targetUrl += vm.SEGMENT_PREFIX + '/' + vm.claim.id + '/';
    } else if (vm.legalfile) {
      targetUrl += vm.legalfile.prefix + '/' + vm.legalfile.id + '/?is_legalfile=true';
    } else {
      return;
    }

    vm.busy = true;

    $http
      .get(targetUrl)
      .then(function(response) {
        vm.loadedFullHistory = true;
        vm.historyChanges = response.data.changes;
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  /**
   * Request client Client for the active claim or client
   * Saves global userClaims variable with the requested data
   *
   * @param {refresh} [boolean] forces refresh the data
   */
  function fetchSameUserClaims(refresh = false) {
    if (!vm.claim || (vm.loadedSameUserClaims && !refresh)) return;

    // for claim, clientID is in client, for clients, is just ID
    const USER_ID = vm.claim.client ? vm.claim.client.id : vm.claim.id;
    const URL = `${serverAddress.getBaseUrl()}api2/v1/clientClaims/${USER_ID}/`;

    vm.busy = true;

    $http
      .get(URL)
      .then(response => {
        vm.loadedSameUserClaims = true;
        vm.userClaims = response.data;

        // If we are in a claim, remove it from results
        if (!vm.claim.client) return;
        const ENDPOINTS = [
          'claims_same_client',
          'bank_claims_same_client',
          'accident_claims_same_client',
          'phone_claims_same_client',
          'laboral_claims_same_client',
          'so_claims_same_client',
          'taxes_claims_same_client',
          'negligences_claims_same_client',
          'managements_claims_same_client',
          'foreignerservices_claims_same_client',
          'legalservices_same_client'
        ];

        for (var i = 0; i < ENDPOINTS.length; i++) {
          if (vm.userClaims[ENDPOINTS[i]]) {
            for (var j = 0; j < vm.userClaims[ENDPOINTS[i]].length; j++) {
              if (vm.userClaims[ENDPOINTS[i]][j].id == vm.claim.id) {
                vm.userClaims[ENDPOINTS[i]].splice(j, 1);
              }
            }
          }
        }

      })
      .catch(() => {
        notifications.addCurrentView('error', 'No se pudo obtener las reclamaciones del usuario');
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  /**
   * Request claim documents and saves in global documents object
   *
   * @param {refresh} [boolean] forces refresh the data
   */
  function fetchDocuments(refresh = false) {
    if (!vm.claim || (vm.loadedDocuments && !refresh)) return; // TODO: should return a promise

    vm.busy = true;
    const URL = `${serverAddress.getBaseUrl()}api2/v1/${vm.MODEL_PROVIDER.toLowerCase()}Documents/${vm.claim_id}/?format=json&limit=0&offset=0&q=&`;

    let deferred = $q.defer();

    $http
      .get(URL)
      .then(response => {
        vm.documentsResponse = response.data.documents;
        vm.documentation_analysis = response.data.documentation_analysis;

        parseDocuments(true, () => {
          deferred.resolve('Everything is fine');
          vm.busy = false;
          vm.loadedDocuments = true;
        });
      });

    return deferred.promise;
  };

  /**
   * Keep valid documents in a separete variable
   *
   * @param {bool} [sort] sort the documents based on id
   * @param {function} [cb] callback applied over the documents array
   */
  function parseDocuments(sort, cb) {
    vm.documents = vm.documentsResponse.filter(d => !d.deleted && !d.is_missing);

    // this is only for airlines
    vm.already_fax = vm.documents.some(d => d.type && d.type.id === 11);

    if (sort) {
      vm.documents.sort((a, b) => b.id - a.id);
    }

    // expediente files, just for bankin
    if(vm.claim.file_documents) {
      vm.file_documents = vm.claim.file_documents
        .filter(d => !d.deleted && !d.is_missing)
        .sort((a, b) => b.id - a.id);
    }

    // post-process loaded documents
    if (cb) return cb(vm.documents);
  }

  /**
   * Copy documenttypes from vm.claim.claim_type to specific controller vars
   *
   * @param {function} [cb] callback applied over the documents arrays. Not currently used.
   * @returns {promise}
   */
  function getDocumentTypes(cb) {
    vm.documentTypes = vm.claim.type.allowed_document_types;
    vm.clientDocumentTypes = vm.claim.type.allowed_document_types.filter(e => e.client_doc);
    // post-process loaded document types
    if (cb) return cb(vm.documentTypes, vm.clientDocumentTypes);
  }

  function finishClaim() {
    vm.busy = true;

    ClaimFactory.finishClaim(vm.claim_id, vm.SEGMENT_PREFIX)
      .then(() => {
        vm.claimDetail.update('Gestión finalizada con éxito :)');
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.busy = false;
      });

  }

  function addCommentSucessCb() {
    vm.claimDetail.update('Comentario añadido con éxito').then(function() {
      vm.fetchHistoryChanges(true);
    });
  }

  function isManagementServices(claim_prefix) {
    return ['EX'].includes(claim_prefix);
  }

  function getDetailTypeLiteral(plural) {
    if (vm.fileId) {
      return plural ? 'expedientes' : 'expediente';
    } else if (vm.clientId) {
      return plural ? 'clientes' : 'cliente';
    } else if (isManagementServices(vm.SEGMENT_PREFIX)) {
      return plural ? 'gestiones' : 'gestión';
    }
    return plural ? 'reclamaciones' : 'reclamación';
  }

  /* Process claim $watch changes and keep claimChanges array with changes names */
  function processClaimChanges(newVal, oldVal) {
    // start checking changes after claim Resource is there and we're making the first change
    if(!newVal || !oldVal || !newVal.$promise || !oldVal.$promise) return;

    const KEYS = Object.keys(newVal);
    const DIFFS = KEYS.filter(key => !angular.equals(newVal[key], vm.initialClaimState[key]));

    if(vm.claimChanges != DIFFS) vm.claimChanges = DIFFS;
  };

  /* The same, just for legalfileData */
  function processLegalfileChanges(newVal, oldVal) {
    // start checking changes after claim Resource is there and we're making the first change
    if(!newVal || !oldVal || !vm.claim || !vm.claim.$promise) return;

    const KEYS = Object.keys(newVal);
    const DIFFS = KEYS.filter(key => !angular.equals(newVal[key], vm.initialClaimState.legalfile[key]));

    if(vm.legalfileChanges != DIFFS) vm.legalfileChanges = DIFFS;
  };

  /* legafile methods */
  function saveLegalFile() {
    vm.busy = true;

    // esto es de accident, hay que revisar
    if(vm.SEGMENT_PREFIX == 'AC') vm.legalfileinfo.made_quantity = vm.claim.compensation || 0;

    return LegalFileFactory.saveLegalFile(vm.SEGMENT_PREFIX, vm.claim_id, vm.legalfileinfo)
      .then(() => {
        vm.claimDetail.update('Información Procesal modificada con exito');
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function deleteLegalFile() {
    vm.busy = true;

    return LegalFileFactory.deleteLegalFile(vm.SEGMENT_PREFIX, vm.claim_id)
      .then(() => {
        vm.claimDetail.update('Eliminada la informacion procesal de la reclamación.');
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function generateLegalFile() {
    vm.busy = true;

    return LegalFileFactory.generateLegalFile(vm.SEGMENT_PREFIX, vm.claim_id)
      .then(() => {
        vm.claimDetail.update('Creado expediente para la reclamación.');
      })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function setActiveTab(event, tab) {
    vm.initialTab = tab;
  }

  function fixIdFromHash(urlId) {
    if(!urlId) return null;

    // detect encoded character # and fix it (continuo if urlId is not a string)
    if(urlId.indexOf && urlId.indexOf('#') !== -1) {
      const oldPath = $location.path();
      // redirect
      $location.path(oldPath.substr(0, oldPath.indexOf('#')));
      // set the right id because until the redirect starts
      // the request starts and launchs errors
      return urlId.substr(0, urlId.indexOf('#'));
    }
    return urlId;
  }

  function savePortfolioAssignment() {
    const isTransferDateValid = () => vm.claim.transfered_to_investor_date && vm.claim.transfered_to_investor_date.length === 10;
    const areAllFieldsFilled = () => vm.claim.transfered_to_investor_date && vm.claim.investor;

    const notifyError = message => notifications.addCurrentView('error', message); // Removed parentheses around `message`
    const saveClaim = () => vm.claimDetail.save(null, 'Reclamación cedida a cartera');

    if (vm.claim.investor.ignore_state_limits) {
      if (areAllFieldsFilled()) {
        if (isTransferDateValid()) {
          saveClaim();
        }
        else {
          notifyError('No se puede marcar como cedida, fecha no válida');
        }
      }
      else {
        notifyError('No se puede marcar como cedida, rellena todos los datos');
      }
    } 
    else {
      if (vm.claim.sales_state !== null) {
        notifyError('No se puede marcar como cedida, la reclamación tiene estado comercial.');
        removeInvestorAsignment(false);
      }
      else if (!vm.claim.state.accepts_transfer_to_portfolio) {
        notifyError('No se puede marcar como cedida, el estado legal no admite esta cesión');
        removeInvestorAsignment(false);
      }
      else {
        if (areAllFieldsFilled()) {
          if (isTransferDateValid()) {
            saveClaim();
          }
          else {
            notifyError('No se puede marcar como cedida, fecha no válida');
          }
        }
        else {
          notifyError('No se puede marcar como cedida, rellena todos los datos');
        }
      }
    }
  }

  function removeInvestorAsignment(show_message) {
    vm.claim.transfered_to_investor_date = null;
    vm.claim.investor = null;
    if(show_message){
      vm.claimDetail.save(null, 'Eliminada reclamación de la cartera');
    }
  }
  function parseDates(dates = [], entity) {
    dates.forEach(d => {
      entity[d] = moment(entity[d] || '');
    });
  }

  function generateFromDocxTemplate(template) {
    vm.busy = true;
    notifications.clear();
    notifications.addCurrentView('info', 'Generando documento... Por favor, espere');
    $http({
      url: serverAddress.getBaseUrl() + 'documents/generate-claim-doc',
      method: 'POST',
      data: {
        claim_id: vm.claim.id,
        segment: vm.SEGMENT_PREFIX,
        template_id: template.id,
        target_doc_type_id: template.target_doctype_id,
        new_draft: 1
      }
    })
      .then(function() {
        vm.claimDetail.update('Operación lanzada, recibirás un email cuando el documento esté listo :-)');
      })
      .catch(function(error) {
        notifications.clear();
        notifications.addCurrentView('error', error.data);
      })
      .finally(() => {
        vm.busy = false;
      });
  }
}
