ValidationService.$inject = ['$http', 'serverAddress'];

export default function ValidationService($http, serverAddress) {
  return {
    validateDocument
  };
  function validateDocument(prefix, documentId, payload) {
    return $http.post(
      `${serverAddress.getBaseUrl()}api2/v1/${prefix}/${documentId}/validate/`,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
}
