
import angular from 'angular';

import LegalFilesDetailCtrl from './legalFilesDetailCtrl';
import LegalFilesListCtrl from './legalFilesListCtrl';
import NotificationsListCtrl from './notificationsListCtrl';
import NotificationsProceduralControlListCtrl from './notificationsProceduralControlListCtrl';

import LegalFile from './legalFileService';
import LegalFileStatisticsGroups from './statisticsGroupsService';
import ProceduralPhaseService from './services/proceduralPhaseService';
import ProceduralLawyerService from './services/proceduralLawyerService';
import NotificationService from './services/notificationService';
import ValidationService from './services/validationService';

import LegalfilesRoutes from './routes';
import LegalfilesConstants from './constants';

angular
  .module('reclamador.segments.legalfiles', [])

  .controller('LegalFilesDetailCtrl', LegalFilesDetailCtrl)
  .controller('LegalFilesListCtrl', LegalFilesListCtrl)
  .controller('NotificationsListCtrl', NotificationsListCtrl)
  .controller('NotificationsProceduralControlListCtrl', NotificationsProceduralControlListCtrl)

  .factory('LegalFile', LegalFile)
  .factory('LegalFileStatisticsGroups', LegalFileStatisticsGroups)
  .factory('ProceduralPhaseService', ProceduralPhaseService)
  .factory('ProceduralLawyerService', ProceduralLawyerService)
  .factory('NotificationService', NotificationService)
  .factory('ValidationService', ValidationService)
  .constant('LegalfilesConstants', LegalfilesConstants)

  .config(LegalfilesRoutes);
