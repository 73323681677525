import $ from 'jquery';

AssigmentModalStep1Ctrl.$inject = [
  'AssigmentModalShared',
  'AssigmentModalStep1Service',
  '$q',
  '$scope',
  '$timeout',
  'toastr'
];

export default function AssigmentModalStep1Ctrl(
  AssigmentModalShared,
  AssigmentModalStep1Service,
  $q,
  $scope,
  $timeout,
  toastr
) {
  const vm = this;

  vm.loadingData = true;
  vm.isSelectOptionsVisible = false;
  vm.totalCounter = 0;
  vm.calculatingTotal = true;

  vm.verticalData = {
    AE: 'Aerolíneas',
    BA: 'Banca',
    AC: 'Accidentes',
    TE: 'Telefonía',
    LA: 'Laboral',
    NE: 'Negligencias',
    SO: 'Seg. Oportunidad',
    TA: 'Impuestos'
  };
  vm.phaseNames = [];
  vm.typeNames = [];
  vm.lawyerNames = [];

  vm.selectedVertical = null;
  vm.selectedPhase = null;
  vm.selectedType = null;
  vm.selectedCurrentLawyer = null;
  vm.selectedAssignedLawyers = [];

  vm.hasAssignedLawyers = false;
  vm.hasNotifications = false;

  vm.toggleLawyersSelect = () => {
    vm.isSelectOptionsVisible = !vm.isSelectOptionsVisible;
  };

  vm.calculateTotal = async () => {
    vm.hasNotifications = false;

    let selectedCurrentLawyer =
      vm.selectedCurrentLawyer && vm.selectedCurrentLawyer.id ? vm.selectedCurrentLawyer.id : null;
    let selectedPhase = vm.selectedPhase && vm.selectedPhase.id;
    let selectedType = vm.selectedType && vm.selectedType.id;

    try {
      vm.calculatingTotal = true;

      const response = await AssigmentModalStep1Service.getTotalData(
        vm.selectedVertical,
        selectedPhase,
        selectedType,
        selectedCurrentLawyer
      );
      $timeout(() => {
        vm.totalCounter = response.objects[0].total_count;
        vm.hasNotifications = vm.totalCounter !== 0;
      }, 1000);
    } catch (error) {
      console.error('Error al calcular el total:', error);
      toastr.error('Error al calcular el total. Por favor, inténtelo de nuevo más tarde.');
    } finally {
      $timeout(() => {
        vm.calculatingTotal = false;
      }, 2000);
    }
  };

  vm.toggleLawyerSelection = () => {
    const hasLawyers = vm.selectedAssignedLawyers.some(lawyer => lawyer !== null);
    vm.hasAssignedLawyers = hasLawyers;

    vm.calculateTotal();
  };

  vm.getSelectedLawyersCount = () => {
    return vm.selectedAssignedLawyers.filter(lawyerId => lawyerId !== null).length;
  };

  const storeData = () => {
    let totalDocuments = vm.totalCounter;

    AssigmentModalShared.set({
      selectedVertical: vm.selectedVertical,
      selectedPhase: vm.selectedPhase,
      selectedType: vm.selectedType,
      selectedCurrentLawyer: vm.selectedCurrentLawyer,
      selectedAssignedLawyers: vm.selectedAssignedLawyers,
      totalDocuments: totalDocuments
    });
  };

  vm.continueStep2 = () => {
    storeData();
    $('#assigmentModalStep2').modal('show');
  };

  const promises = [
    AssigmentModalStep1Service.getPhaseData(),
    AssigmentModalStep1Service.getTypeData(),
    AssigmentModalStep1Service.getLawyersData(),
    AssigmentModalStep1Service.getTotalData()
  ];

  $q.all(promises)
    .then(responses => {
      vm.phaseNames = responses[0].objects.map(obj => ({
        id: obj.id,
        name: obj.name,
        resource_uri: obj.resource_uri
      }));
      vm.typeNames = responses[1].objects.map(obj => ({
        id: obj.id,
        name: obj.name,
        resource_uri: obj.resource_uri
      }));
      vm.lawyerNames = responses[2].objects.map(lawyer => ({
        id: lawyer.id,
        resource_uri: lawyer.resource_uri,
        fullName: `${lawyer.first_name} ${lawyer.last_name}`
      }));
      vm.totalCounter = responses[3].objects[0].total_count;

      vm.loadingData = false;
      vm.calculatingTotal = false;
    })
    .catch(error => {
      console.error('Error en alguna de las solicitudes:', error);
      toastr.error(
        'Error en alguna de las solicitudes de asignación. Por favor, inténtelo de nuevo más tarde.'
      );
      vm.calculatingTotal = true;
    });

  vm.cancelModal = () => {
    $scope.$emit('clearModalFields');
  };

  $scope.$on('clearModalFields', () => {
    vm.selectedVertical = null;
    vm.selectedPhase = null;
    vm.selectedType = null;
    vm.selectedCurrentLawyer = null;
    vm.selectedAssignedLawyers = [];
    vm.hasAssignedLawyers = false;

    vm.calculateTotal();
  });
}
