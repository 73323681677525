const LegalfilesConstants = {
  ALIAS: 'legalfiles',
  AMOUNTS_TYPES: [
    { id: 'Minuta', label: 'Minuta reclamador' },
    { id: 'Procurador', label: 'Procurador' },
    { id: 'Perito', label: 'Perito' },
    { id: 'Otros', label: 'Otros conceptos' }
  ],

  ordinary_procesal_phase_options: [
    { id: 'lawsuit', name: 'Interposición de la demanda o contestación' },
    { id: 'hearing', name: 'Audiencia Previa' },
    { id: 'trial', name: 'Fase de Juicio' }
  ],

  verbal_procesal_phase_options: [
    { id: 'lawsuit', name: 'Interposición de la demanda y contestación' },
    { id: 'view', name: 'Acto de la vista' }
  ],

  PROCURADOR_BILL_CONCEPT: [
    { id: 'fav_sin_costas', value: 'Sentencia favorable sin costas' },
    { id: 'desfav', value: 'Sentencia desfavorable' },
    { id: 'acuerdo', value: 'Acuerdo' },
    { id: 'desestimiento', value: 'Desestimiento' },
    { id: 'fav_con_costas', value: 'Sentencia favorable con costas' }
  ],

  EXTERNAL_LAWYER_BILL_CONCEPT: [
    { id: 'audiencia_previa', value: 'Audiencia previa' },
    { id: 'vista', value: 'Vista' },
    { id: 'juicio', value: 'Juicio' },
    { id: 'smac', value: 'SMAC' },
    { id: 'gastos', value: 'Gastos' }
  ],

  VIEW_TYPE: [
    { id: 0, value: 'Presencial'},
    { id: 1, value: 'Telemática'}
  ],

  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/legalfiles/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Reclamaciones',
      indexKey: 'reclamaciones',
      template: 'js/modules/segments/legalfiles/partials/detail/claims.html',
      select: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/legalfiles/partials/detail/documentation.html',
      select: null
    },
    {
      heading: 'Costas e intereses',
      indexKey: 'costas',
      template: 'js/modules/segments/legalfiles/partials/detail/interests.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: 'js/modules/segments/legalfiles/partials/detail/historial.html',
      select: null,
      headingBadge: null
    }
  ],
  defaultColumnDef: [
    { headerName: 'Nº Exp', fieldName: 'year,num_legalfile', sortable: true, alwaysVisible: true },
    { headerName: 'Clientes', fieldName: 'client_names', sortable: true, hidden: false },
    { headerName: 'Nº de Autos', fieldName: 'autos', sortable: true, hidden: false },
    { headerName: 'Nº Autos Ejec', fieldName: 'autos_exec', sortable: true, hidden: false },
    { headerName: 'Procurador', fieldName: 'procurador_name', sortable: true, hidden: false },
    { headerName: 'Tribunal', fieldName: 'court', sortable: true },
    { headerName: 'Compañía pide vista', fieldName: 'ask_for_view', sortable: true },
    { headerName: 'Fecha de vista', fieldName: 'view_date', sortable: true },
    {
      headerName: 'Asistencia del cliente al juicio',
      fieldName: 'trial_attendance',
      sortable: true
    },
    { headerName: 'Audiencia Previa', fieldName: 'advance_hearing_date', sortable: true },
    { headerName: 'Abogado', fieldName: 'claim_lawyer', sortable: true },
    { headerName: 'Letrado externo', fieldName: 'externalcourtlawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'extra_file_state', sortable: true },
    { headerName: 'Estado de las costas', fieldName: 'costs_state_id', sortable: true },
    { headerName: 'Empresa Demandada', fieldName: 'extra_against', sortable: true },
    {
      headerName: 'Dirección procurador',
      fieldName: 'procurador_address',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Dest. acuerdo extraprocesal',
      fieldName: 'has_agreement_trial_dismiss',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Dest. disposición partes',
      fieldName: 'has_parts_disposition_dismiss',
      sortable: true,
      hidden: true
    },
    { headerName: '€ Reclamados', fieldName: 'claimed_amount', sortable: true },
    { headerName: 'Cantidad Obtenida', fieldName: 'made_quantity', sortable: true },
    {
      headerName: 'Copia demanda sellada',
      fieldName: 'signed_lawsuit_copy',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Fecha copia demanda sellada',
      fieldName: 'signed_lawsuit_copy_date',
      sortable: true,
      hidden: false
    },
    { headerName: 'Costas', fieldName: 'has_legal_costs', sortable: true },
    {
      headerName: 'Costas Cantidad',
      fieldName: 'legal_costs_amount',
      sortable: true,
      hidden: true
    },
    { headerName: 'Intereses', fieldName: 'legal_interests', sortable: true },
    {
      headerName: 'Intereses Consignados',
      fieldName: 'legal_interests_allocated',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Intereses Facturados',
      fieldName: 'legal_interests_collected',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Intereses Cantidad',
      fieldName: 'legal_interests_amount',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo legal (vuelo)',
      fieldName: 'flight_legal_type',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Vuelo con sentencias favorables',
      fieldName: 'flight_has_favorable_sentences',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Vuelo con sentencias desfavorables',
      fieldName: 'flight_has_unfavorable_sentences',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha ultimo documento',
      fieldName: 'last_document',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha cambio de estado',
      fieldName: 'state_change_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Reclamaciones', fieldName: 'total_claims', sortable: true },
    {
      headerName: 'Tiene reclamaciones con puenteo',
      fieldName: 'bypass',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha presentación costas',
      fieldName: 'legal_costs_calculation_date',
      sortable: true
    },
    { headerName: 'Fecha caducidad mandamiento', fieldName: 'commandment_date', sortable: true },
    { headerName: 'Fecha actualiz.', fieldName: 'date_updated', sortable: true },
    { headerName: 'Fecha creación', fieldName: 'date_added', sortable: true },
    { headerName: 'Tipo de reclamación', fieldName: 'claim_type_name', sortable: true },
    { headerName: 'Nº Autos Verbal/Ordinario', fieldName: 'autos_unpaid', sortable: true, hidden: false }
  ],
  filterFieldInfo: {
    view_date__gte: {
      label: 'Fecha vista desde',
      type: 'date',
      column: 'claim.view_date',
      order: '>='
    },
    view_date__lte: {
      label: 'Fecha vista hasta',
      type: 'date',
      column: 'claim.view_date',
      order: '<='
    },
    advance_hearing_date__gte: {
      label: 'Fecha audiencia previa desde',
      type: 'date',
      column: 'claim.advance_hearing_date',
      order: '>='
    },
    advance_hearing_date__lte: {
      label: 'Fecha audiencia previa hasta',
      type: 'date',
      column: 'claim.advance_hearing_date',
      order: '<='
    },
    court_id: { label: 'Juzgado', select_id: 'claim-court-select' },
    autos: { label: 'Numero de Auto', select_id: 'claim-auto' },
    autos_exec: { label: 'Numero de Auto Ejecución', select_id: 'claim-auto-exec' },
    autos_unpaid: { label: 'Numero de Auto Verbal/Ordinario', select_id: 'claim-auto-unpaid' },
    procurador_id: { label: 'Procurador', select_id: 'claim-procurador-select' },
    externalcourtlawyer_id: {
      label: 'Letrado externo',
      select_id: 'claim-externalcourtlawyer-select'
    },
    claim_lawyer_id: { label: 'Abogado', select_id: 'claim-lawyer-select' },
    airlinesued_airline: { label: 'Empresa Demandada' },
    has_legal_costs: { label: 'Costas', type: 'boolean' },
    red_procuradores: { label: 'Red de procuradores', select_id: 'red_procuradores_select' },
    legal_interests: { label: 'Intereses', type: 'boolean' },
    legal_interests_allocated: { label: 'Intereses consignados', type: 'boolean', hidden: true },
    legal_interests_collected: { label: 'Intereses facturados', type: 'boolean', hidden: true },
    client_name: { label: 'Nombre', type: 'text' },
    client_surnames: { label: 'Apellidos', type: 'text' },
    legalfile_groups: {
      label: 'Grupo según estado de expediente',
      select_id: 'legal_file_group_select'
    },
    extra_file_state: { label: 'Estado del expediente', select_id: 'extra_file_state' },
    costs_state_id: { label: 'Estado de las costas', select_id: 'costs_state_id' },
    extra_against: { label: 'Empresa demandada', json_value: 'true', use_field: 'name' },
    digital_sign_via_signaturit: { label: 'Firma via Signaturit', type: 'signaturit' },
    sent_digital_lawsuit: { label: 'Demanda enviada', type: 'signaturit' },
    digital_lawsuit_signed: { label: 'Demanda firmada', type: 'signaturit' },
    digital_lawsuit_not_signed: { label: 'Demanda no firmada', type: 'signaturit' },
    trial_attendance: { label: 'Asistencia a juicio', select_id: 'trial_attendance-select' }
  },
  VALID_NEW_FILTERS: [
    'client_name',
    'client_surnames',
    'autos',
    'view_date__gte',
    'view_date__lte',
    'advance_hearing_date__gte',
    'advance_hearing_date__lte',
    'autos_exec',
    'has_legal_costs',
    'legal_costs_allocated',
    'legal_costs_collected',
    'autos_unpaid'
  ],
  defaultColumnDef_Notifications: [
    { headerName: 'CASO'},
    { headerName: 'TIPO'},
    { headerName: 'FASE'},
    { headerName: 'ABOGADO'},
    { headerName: 'VENCIMIENTO'},
    { headerName: 'ACCIONES' }
  ],
  filterFieldInfo_Notifications: {
    validation: {
      label: 'Validación', column: 'is_validated', select_id: 'procedural-validation-select', json_value: true,
      filterDisplay: validation => validation.name
    },
    proceduralLawyer: {
      label: 'Abogado', column: 'lawyer', select_id: 'procedural-lawyer-select', json_value: true,
      filterDisplay: lawyer => (lawyer.first_name ? (lawyer.first_name + ' ' + lawyer.last_name) : lawyer.username)
    },
    documentType: {
      label: 'Tipo', column: 'type', select_id: 'document-type-select', json_value: true,
      filterDisplay: documentType => documentType.name
    },
    proceduralPhase: {
      label: 'Fase', column: 'procedural_phase', select_id: 'procedural-phase-select', json_value: true,
      filterDisplay: proceduralPhase => proceduralPhase.name
    },
    dueDate: {
      label: 'Fecha de vencimiento', column: 'due_date_filter', select_id: 'due-date-select', json_value: true,
      filterDisplay: dueDate => dueDate.name
    },
    priority: {
      label: 'Ordenación', column: 'show_first', select_id: 'priority-select', json_value: true,
      filterDisplay: priority => priority.name
    }
  },
  validationOptions_Notifications: [
    { id: 'true', name: 'Validado'},
    { id: 'false', name: 'Sin validar'}
  ],
  dueDateOptions_Notifications: [
    { id: 'grace', name: 'Gracia'},
    { id: 'today', name: 'Hoy'},
    { id: 'tomorrow', name: 'Mañana'},
    { id: 'in_2_days', name: 'Pasado mañana'},
    { id: '3_to_5_days', name: '3 a 5 días'},
    { id: 'more_than_5_days', name: '+ de 5 días'},
    { id: 'without_due_date', name: 'Sin plazo'}
  ],
  defaultColumnDef_NotificationsProceduralControl: [
    { headerName: 'TIPO' },
    { headerName: 'SIN VALIDAR', sortable: true, fieldName: 'unvalidated_count' },
    { headerName: 'SIN ASIGNAR', sortable: true, fieldName: 'unassigned_count' },
    { headerName: 'GRACIA', sortable: true, fieldName: 'grace_due_date_count' },
    { headerName: 'HOY', sortable: true, fieldName: 'today_due_date_count' },
    { headerName: 'MAÑANA', sortable: true, fieldName: 'tomorrow_due_date_count' },
    { headerName: 'TOTAL', sortable: true, fieldName: 'total_count' }
  ],
  filterFieldInfo_NotificationsProceduralControl: {
    proceduralLawyer: {
      label: 'Abogado', column: 'lawyer', select_id: 'procedural-lawyer-select', json_value: true,
      filterDisplay: lawyer => (lawyer.first_name ? (lawyer.first_name + ' ' + lawyer.last_name) : lawyer.username)
    },
    documentType: {
      label: 'Tipo', column: 'type', select_id: 'document-type-select', json_value: true,
      filterDisplay: documentType => documentType.name
    }
  }
};

export default LegalfilesConstants;
